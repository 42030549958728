import React from "react";
import SearchFee from "./Components/SearchFee";
import TableFee from "./Components/TableFee";

const Totalfee = () => {
  return (
    <div className="">
      <h1 className="text-[22px] font-bold mb-[15px] text-[#4e4e4e]">
        Tổng phí
      </h1>
      <SearchFee />
      <TableFee />
    </div>
  );
};

export default Totalfee;

import Imgdefault from "./imgs/default.png";
import Logo from "./imgs/logo.jpg";
import ConDau from "./imgs/condau.jpg";


const IMAGES = {
    Imgdefault,Logo,ConDau
};

export default IMAGES;

import { Form, Input, Button, Switch, Spin } from "antd";
import ImgCrop from "antd-img-crop";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";

import React, { useEffect, useState } from "react";
import { Upload } from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { auth, db, storage } from "../../../../config/firebase.config";

const Remind = () => {
  const [loading, setLoading] = useState(true);
  const [formEdit] = Form.useForm();
  const [data, setData] = useState<any[]>([]);
  console.log(data);

  useEffect(() => {
    const formRemind = data.map((item) => item.formRemind);
    if (formRemind.length > 0) {
      formEdit.setFieldsValue({
        tieuDeRutThatBai: formRemind[0].tieuDeRutThatBai,
        noiDungDuyet: formRemind[0].noiDungDuyet,
        NutNhanRutTien: formRemind[0].NutNhanRutTien,
        thongBaoKhiViBiDongBang: formRemind[0].thongBaoKhiViBiDongBang,
        tieuDeRutTienThanhCong: formRemind[0].tieuDeRutTienThanhCong,
        thongBaoRutTienThanhCong: formRemind[0].thongBaoRutTienThanhCong,
        tuVanHoTro: formRemind[0].tuVanHoTro,
        nhacNhoVayTien: formRemind[0].nhacNhoVayTien,
        ghiChuKhiDangKyKhoanVay: formRemind[0].ghiChuKhiDangKyKhoanVay,
      });
      setLoading(false);
    }
  }, [data, formEdit]);
  useEffect(() => {
    const collectionRef = collection(db, "remind");
    const unsub = onSnapshot(collectionRef, (snapshot) => {
      let datas: any[] = [];
      snapshot.forEach((doc) => {
        datas.push({ ...doc.data(), id: doc.id });
      });
      setLoading(false)
      setData(datas);
    });
    return unsub;
  }, []);
  const onFinish = async (value: object) => {
    const user = auth.currentUser;
    setLoading(true);
    if (user) {
      const userDocRef = doc(db, "remind", user.uid);
      const docSnap = await getDoc(userDocRef);
      if (!docSnap.exists()) {
        await setDoc(userDocRef, {
          formRemind: value,
        });
        setLoading(false);
        alert("Thêm thành cong");
      } else {
        await updateDoc(userDocRef, {
          formRemind: value,
        });
        setLoading(false);
        alert("Update thành cong");
      }
    }
  };
  return (
    <div className="bg-white px-[20px] py-[30px]">
      <Spin spinning={loading}>
        <Form
          form={formEdit}
          autoComplete="off"
          initialValues={{ remember: true }}
          layout="vertical"
          onFinish={onFinish}
          name="form_item_path"
        >
          <Form.Item
            label=<div className="font-bold">Tiêu đề rút tiền thất bại</div>
            name="tieuDeRutThatBai"
          >
            <Input placeholder="Tiêu đề rút tiền thất bại" />
          </Form.Item>
          <Form.Item
            name="noiDungDuyet"
            label=<div className="font-bold">Nội dung duyệt</div>
          >
            <Input placeholder="Nội dung duyệt" />
          </Form.Item>
          <Form.Item
            name="NutNhanRutTien"
            label=<div className="font-bold">Nút nhấn rút tiền</div>
          >
            <Input placeholder="Nút nhấn rút tiền" />
          </Form.Item>
          <Form.Item
            name="thongBaoKhiViBiDongBang"
            label=<div className="font-bold">Thông báo khi ví bị đóng băng</div>
          >
            <Input placeholder="Thông báo khi ví bị đóng băng" />
          </Form.Item>
          <Form.Item
            name="tieuDeRutTienThanhCong"
            label=<div className="font-bold">Tiêu đề rút tiền thành công</div>
          >
            <Input placeholder="Tiêu đề rút tiền thành công" />
          </Form.Item>
          <Form.Item
            name="thongBaoRutTienThanhCong"
            label=<div className="font-bold">Thông báo rút tiền thành công</div>
          >
            <Input placeholder="Thông báo rút tiền thành công" />
          </Form.Item>
          <Form.Item
            name="tuVanHoTro"
            label=<div className="font-bold">Tư vấn - hỗ trợ</div>
          >
            <Input placeholder="Tư vấn - hỗ trợ" />
          </Form.Item>

          <Form.Item
            name="nhacNhoVayTien"
            label=<div className="font-bold">Nhắc nhở vay tiền</div>
          >
            <Input placeholder="Nhắc nhở vay tiền" />
          </Form.Item>
          <Form.Item
            name="ghiChuKhiDangKyKhoanVay"
            label=<div className="font-bold">Ghi chú khi đăng ký khoản vay</div>
          >
            <Input placeholder="Ghi chú khi đăng ký khoản vay" />
          </Form.Item>
          <button className="p-[5px]  w-[80px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]">
            Lưu
          </button>
        </Form>
      </Spin>
    </div>
  );
};

export default Remind;

import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Switch, Spin } from "antd";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../../config/firebase.config";
interface UpdateInfor {
  id: string;
  user: any
}
const UpdateInfor: React.FC<UpdateInfor> = ({ id, user }) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(false);

  const onFinish = async (value: object) => {
    setLoading(true);
    const docRef = doc(db, "users", id);

    console.log(value);

    updateDoc(docRef, value).then(() => {
      setLoading(false);
      window.location.reload();
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });
  };

  if (loading) return (
    <div className="py-[60px] flex justify-center items-center">
      <Spin />
    </div>
  )

  console.log(user);


  return (
    <div>
      <Form name="form_item_path" layout="vertical" onFinish={onFinish} initialValues={user}>
        <div className="flex flex-col md:flex-row lg:flex-row gap-[15px]">
          <div>
            <Form.Item
              label=<div className="font-bold mt-[10px]">Tên</div>
              name="fullName"
            >
              {/* IF (user?.fullName) return value = user?.fullName */}
              <Input placeholder="Tên" />
            </Form.Item>
            <Form.Item
              label=<div className="font-bold mt-[10px]">SĐT</div>
              name="myPhoneNumber"
            >
              <Input placeholder="Ghi chú" />
            </Form.Item>
            <Form.Item
              label=<div className="font-bold mt-[10px]">Ngày sinh</div>
              name="dateOfBirth"
            >
              <Input placeholder="Ngày sinh" />
            </Form.Item>
            <Form.Item
              label=<div className="font-bold mt-[10px]">Giới tính</div>
              name="genre"
            >
              <Input placeholder="Giới tính" />
            </Form.Item>
            <Form.Item
              label=<div className="font-bold mt-[10px]">cmnd</div>
              name="cmnd"
            >
              <Input placeholder="cmnd" />
            </Form.Item>

          </div>
          <div>
            <Form.Item
              label=<div className="font-bold mt-[10px]">Nghề nghiệp</div>
              name="job"
            >
              <Input placeholder="Nghề nghiệp" />
            </Form.Item>
            <Form.Item
              label=<div className="font-bold mt-[10px]">Lương</div>
              name="income"
            >
              <Input placeholder="Lương" />
            </Form.Item>
            <Form.Item
              label=<div className="font-bold mt-[10px]">Lý do ghi nợ</div>
              name="purpose"
            >
              <Input placeholder="Lý do ghi nợ" />
            </Form.Item>
            <Form.Item
              label=<div className="font-bold mt-[10px]">SĐT người thân</div>
              name="phoneNumber"
            >
              <Input placeholder="SĐT người thân" />
            </Form.Item>
            <Form.Item
              label=<div className="font-bold mt-[10px]">Loại người thân</div>
              name="relationship"
            >
              <Input placeholder="Loại người thân" />
            </Form.Item>
          </div>
        </div>
        <div className="flex gap-[15px] justify-end mt-[40px] border-t pt-[10px]">
          <button className="p-[5px]  w-[100px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]">
            {t("Cancle")}
          </button>
          <button className="p-[5px]  w-[100px] min-w-[40px]  bg-[#0cc77f]  text-[#fff] duration-300 hover:text-yellow-300  hover:bg-[#0a965b]">
            {t("Addmoney")}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default UpdateInfor;

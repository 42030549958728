import { Form, Input, Button, Switch, Spin } from "antd";
import ImgCrop from "antd-img-crop";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";

import React, { useEffect, useState } from "react";
import { Upload } from "antd";
import { auth, db, storage } from "../../../../config/firebase.config";
const Bank = () => {
  const [loading, setLoading] = useState(true);
  const [formEdit] = Form.useForm();
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const formBank = data.map((item) => item.formBank);
    if (formBank.length > 0) {
      formEdit.setFieldsValue({
        tenNganHang: formBank[0].tenNganHang,
        soTaiKhoan: formBank[0].soTaiKhoan,
        TenChuTKhoan: formBank[0].TenChuTKhoan,
        tieuDeSoTK: formBank[0].tieuDeSoTK,
        hienThiNganHangTraNo: formBank[0].hienThiNganHangTraNo,
      });
      setLoading(false);
    }
  }, [data, formEdit]);
  useEffect(() => {
    const collectionRef = collection(db, "bankSetting");
    const unsub = onSnapshot(collectionRef, (snapshot) => {
      console.log(123);
      
      let datas: any[] = [];
      snapshot.forEach((doc) => {
        datas.push({ ...doc.data(), id: doc.id });
      });
      setLoading(false)
      setData(datas);
    });
    return unsub;
  }, []);
  const onFinish = async (value: object) => {
    const user = auth.currentUser;
    setLoading(true);
    if (user) {
      const userDocRef = doc(db, "bankSetting", user.uid);
      const docSnap = await getDoc(userDocRef);
      if (!docSnap.exists()) {
        await setDoc(userDocRef, {
          formBank: value,
        });
        setLoading(false);
        alert("Thêm thành cong");
      } else {
        await updateDoc(userDocRef, {
          formBank: value,
        });
        setLoading(false);
        alert("Update thành cong");
      }
    }
  };
  return (
    <div className="bg-white px-[20px] py-[30px]">
      <Spin spinning={loading}>
        <Form
          form={formEdit}
          name="form_item_path"
          layout="vertical"
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            label=<div className="font-bold">Tên ngân hàng</div>
            name="tenNganHang"
          >
            <Input placeholder="Tên ngân hàng" />
          </Form.Item>
          <Form.Item
            name="soTaiKhoan"
            label=<div className="font-bold">Số tài khoản</div>
          >
            <Input placeholder="Số tài khoản" />
          </Form.Item>
          <Form.Item
            name="TenChuTKhoan"
            label=<div className="font-bold">Tên chủ tài khoản</div>
          >
            <Input placeholder="Tên chủ tài khoản" />
          </Form.Item>
          <Form.Item
            name="tieuDeSoTK"
            label=<div className="font-bold">
              Tiêu đề số tài khoản ngân hàng
            </div>
          >
            <Input placeholder="Tiêu đề số tài khoản ngân hàng" />
          </Form.Item>
          <Form.Item
            name="hienThiNganHangTraNo"
            label=<div className="font-bold">Hiện thị ngân hàng trả nợ</div>
            valuePropName="checked"
            >
            <Switch />
          </Form.Item>
          <button className="p-[5px]  w-[80px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]">
            Lưu
          </button>
        </Form>
      </Spin>
    </div>
  );
};

export default Bank;

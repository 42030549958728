import React, { useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import type { MenuProps } from "antd";
import { Button, Dropdown, Layout, Menu, Space, TreeSelect, theme } from "antd";
const { Header, Content, Sider } = Layout;

const Setting = () => {
  type MenuItem = Required<MenuProps>["items"][number];

  // HANDLE ITEM MENU
  function getItem(
    label: React.ReactNode,
    to: string,
    icon?: React.ReactNode,
    children?: MenuItem[]
  ): MenuItem {
    return {
      key: to,
      icon,
      children,
      label: <NavLink to={to}>{label}</NavLink>,
    } as MenuItem;
  }
  // DATA MENU
  const items: MenuItem[] = [
    getItem("Cấu hình chung", "/setting"),
    getItem("Liên hệ", "/setting/contact"),
    getItem("Cấu hình vay", "/setting/debit"),
    getItem("Ngân hàng", "/setting/bank"),
    getItem("Nhắc nhở", "/setting/remind"),
    getItem("Banner", "/setting/banner"),
  ];
  return (
    <div className="main__layout__setting">
      <Layout style={{ minHeight: "50vh" }}>
        <Sider
          className="z-0 h-[50vh] px-[23px]"
          theme="light"
          width={"541px"}
        >
          <Menu
            theme="light"
            defaultSelectedKeys={["1"]}
            mode="inline"
            items={items}
          ></Menu>
        </Sider>
        <Layout className="layout-header">
          <Content
            className={`content__layout__admin mt-[10px] pl-[30px] h-full `}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default Setting;

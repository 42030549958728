import { Form, Input, Button, Switch, Spin } from "antd";
import React, { useEffect, useState } from "react";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { auth, db, storage } from "../../../../config/firebase.config";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
const Contact = () => {
  const [loading, setLoading] = useState(true);
  const [formEdit] = Form.useForm();
  const [data, setData] = useState<any[]>([]);
  // console.log(data);

  useEffect(() => {
    const formComtac = data.map((item) => item?.formComtac);
    // console.log(formComtac);
    // console.log(formComtac[0].hotLine);

    if (formComtac.length > 0) {
      formEdit.setFieldsValue({
        hotLine: formComtac[0]?.hotLine,
        linkFB: formComtac[0]?.linkFB,
        linkZL: formComtac[0]?.linkZL,
        linkTeleram: formComtac[0]?.linkTeleram,
        linkSDT: formComtac[0]?.linkSDT,
        tienIch1: formComtac[0]?.tienIch1,
        tienIch2: formComtac[0]?.tienIch2,
        tienIch3: formComtac[0]?.tienIch3,
        checkHienThiSo: formComtac[0]?.checkHienThiSo,
      });
      setLoading(false);
    }
  }, [data, formEdit]);
  useEffect(() => {
    const collectionRef = collection(db, "desige");
    const unsub = onSnapshot(collectionRef, (snapshot) => {
      let datas: any[] = [];
      snapshot.forEach((doc) => {
        datas.push({ ...doc.data(), id: doc.id });
      });
      setLoading(false)
      setData(datas);
    });
    return unsub;
  }, []);
  const onFinish = async (value: object) => {
    const user = auth.currentUser;

    if (user) {
      const userDocRef = doc(db, "desige", user.uid);
      const docSnap = await getDoc(userDocRef);
      if (!docSnap.exists()) {
        await setDoc(userDocRef, {
          formComtac: value,
        });
        alert("Thêm thành cong");
      } else {
        await updateDoc(userDocRef, {
          formComtac: value,
        });
        alert("Update thành cong");
      }
    }
  };
  return (
    <div className="bg-white px-[20px] py-[30px]">
      <Spin spinning={loading}>
        <Form
          form={formEdit}
          autoComplete="off"
          initialValues={{ remember: true }}
          name="form_item_path"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            name="hotLine"
            label=<div className="font-bold">Hotline</div>
          >
            <Input placeholder="Hotline" />
          </Form.Item>
          <Form.Item
            name="linkFB"
            label=<div className="font-bold">Link FB Messenger</div>
          >
            <Input placeholder="Link FB Messenger" />
          </Form.Item>
          <Form.Item
            name="linkZL"
            label=<div className="font-bold">Link zalo</div>
          >
            <Input placeholder="Link zalo" />
          </Form.Item>
          <Form.Item
            name="linkTeleram"
            label=<div className="font-bold">Link Telegram</div>
          >
            <Input placeholder="Link Telegram" />
          </Form.Item>
          <Form.Item
            name="linkSDT"
            label=<div className="font-bold">Link Số điện thoại</div>
          >
            <Input placeholder="Link số điện thoại" />
          </Form.Item>
          <Form.Item
            name="tienIch1"
            label=<div className="font-bold">Tiện ích 1</div>
          >
            <Input placeholder="Tiện ích 1" />
          </Form.Item>
          <Form.Item
            name="tienIch2"
            label=<div className="font-bold">Tiện ích 2</div>
          >
            <Input placeholder="Tiện ích 2" />
          </Form.Item>
          <Form.Item
            name="tienIch3"
            label=<div className="font-bold">Tiện ích 3</div>
          >
            <Input placeholder="Tiện ích 3" />
          </Form.Item>
          <Form.Item
            name="checkHienThiSo"
            label=<div className="font-bold">Hiện thị số Hotline</div>
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <button className="p-[5px]  w-[80px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]">
            Lưu
          </button>
        </Form>
      </Spin>
    </div>
  );
};

export default Contact;

import React, { useState } from "react";
import { Space, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useAppDispatch } from "../../../../store";
import { ModalProjectAction } from "../../../../store/Managermodal";
import Update from "../Modal/Update";
import Block from "../Modal/Block";
import Delete from "../../../Overview/Componenst/Modal/Delete";
// DATA TYPE
interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const TableStaff = ({ data, loading }: any) => {

  const AppDispath = useAppDispatch();
  // DATA COL
  const columns: ColumnsType<DataType> = [
    {
      title: "Id",
      dataIndex: "key",
      key: "key",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Tên đăng nhập",
      dataIndex: "nameAccount",
      key: "nameAccount",
    },
    {
      title: "SĐT",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Tên",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Thông tin",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <div className="flex gap-[10px]">
          <span className={`bg-[#008000] px-[10px] py-[2px] text-[#fff] text-[12px] ${status === 'active' ? 'bg-[#e1e42b]' : 'bg-[#ff0000]'}`}>
            {status === 'active' ? 'Hoạt động' : 'Ngưng hoạt động'}
          </span>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="flex gap-[10px]">
          <button
            onClick={() => {
              AppDispath(
                ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                  content: <Block id={(record as any).uid} status={(record as any).status} />,
                  title: "",
                  width: "500px",
                })
              );
            }}
            className="p-[5px]  w-[40px] min-w-[40px]  bg-white rounded-[5px] border border-red-500 text-red-500 duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]"
          >
            <span role="img" aria-label="lock" className="anticon anticon-lock">
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="lock"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M832 464h-68V240c0-70.7-57.3-128-128-128H388c-70.7 0-128 57.3-128 128v224h-68c-17.7 0-32 14.3-32 32v384c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V496c0-17.7-14.3-32-32-32zM332 240c0-30.9 25.1-56 56-56h248c30.9 0 56 25.1 56 56v224H332V240zm460 600H232V536h560v304zM484 701v53c0 4.4 3.6 8 8 8h40c4.4 0 8-3.6 8-8v-53a48.01 48.01 0 10-56 0z"></path>
              </svg>
            </span>
          </button>
          <button
            onClick={() => {
              AppDispath(
                ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                  content: <Update id={(record as any).uid} editData={record} />,
                  title: "Cập nhật người dùng",
                  width: "500px",
                })
              );
            }}
            className="p-[5px]  w-[40px] min-w-[40px]  bg-[#078ddb] rounded-[5px] text-white  duration-300 hover:bg-white hover:text-[#078ddb] hover:border-[#078ddb] hover:border"
          >
            <span role="img" aria-label="edit" className="anticon anticon-edit">
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="edit"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 000-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 009.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z"></path>
              </svg>
            </span>
          </button>
          <button
            onClick={() => {
              AppDispath(
                ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                  content: <Delete id={(record as any).id} />,
                  title: "",
                  width: "500px",
                })
              );
            }}
            className="p-[5px]  w-[40px] min-w-[40px]  bg-red-500 rounded-[5px] text-white  duration-300 hover:bg-red-800   "
          >
            <span
              role="img"
              aria-label="delete"
              className="anticon anticon-delete"
            >
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="delete"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
              </svg>
            </span>
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="p-[24px] bg-white">

      <Table columns={columns} dataSource={data} loading={loading} />
    </div>
  );
};

export default TableStaff;

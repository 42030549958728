import React, { useEffect, useState } from "react";
import SearchLoans from "./Components/Searchloans";
import TableLoans from "./Components/Tableloans";
import { db } from "../../config/firebase.config";
import { collection, onSnapshot } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

function removeTones(str: string) {
  
  // remove accents
  var from =
      "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ",
    to =
      "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(RegExp(from[i], "gi"), to[i]);
  }

  str = str.toLowerCase().trim();
  // .replace(/[^a-z0-9\-]/g, '-')
  // .replace(/-+/g, '-');

  return str;
}

const Loanslist = () => {
  const [loading, setLoading] = useState(true);
  const [loans, setLoans] = useState<any[]>([]);
  const [totalLoan, setTotalLoan] = useState(0);
  const [totalApprovedLoan, setTotalApprovedLoan] = useState(0);
  const [filter, setFilter] = useState({
    maDon: "",
    name: "",
    status: "",
    tuNgay: "",
    denNgay: "",
    thoiGian: "",
  });
  const [user, setUser] = useState<any>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(db.app), (userAuth) => {
      // Check if a user is signed in or signed out
      if (userAuth) {
        setUser(userAuth);
      }
    });

    // Clean up the subscription to avoid memory leaks
    return () => unsubscribe();
  }, [user]);

  // Format tiền
  function formatMoney(number: number) {
    number = Math.round(number * 1000) / 1000;

    return number
      .toFixed(0)
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const getTotalLoan = () => {
    let total = 0;
    let totalApproved = 0;
    loans.forEach((loan) => {
      console.log(loan);
      if (loan.status === "approved") {
        totalApproved += loan.loan.loanAmount;
      } else if (loan.status === "pending") {
        total += loan.loan.loanAmount;
      }
    });

    setTotalLoan(total);
    setTotalApprovedLoan(totalApproved);
  };

  const onSearch = async (searchData: any) => {
    console.log(searchData.value);
    setLoading(true);
    setFilter(searchData.value);
  };

  useEffect(getTotalLoan, [loans]);

  useEffect(() => {
    const collectionRef = collection(db, "loan-list");
    const unsub = onSnapshot(collectionRef, (snapshot) => {
      let loans: any[] = [];
      snapshot.forEach((doc) => {
        const data = doc.data();

        if (!data.loan) return;

        if (filter?.name) {
          const filterName = removeTones(filter?.name);
          const fullName = removeTones(data.fullName);
          if (!fullName.includes(filterName)) return;
        }
        if (filter?.maDon) {
          const filterDon = removeTones(filter?.maDon);
          const fullDon = removeTones(data.idContract);
          if (!fullDon.includes(filterDon)) return;
        }
        if (filter?.status) {
          const filterStatus = removeTones(filter?.status);
          const fullStatus = removeTones(data.status);
          if (!fullStatus.includes(filterStatus)) return;
        }
        // if (filter?.name) {
        //   const filterName = removeTones(filter?.name);
        //   const fullName = removeTones(data.fullName);
        //   console.log("BREAK");
        //   if (!fullName.includes(filterName)) return;
        // }

        

        loans.push({ ...doc.data(), id: doc.id });
      });
      setLoans(loans);
      setLoading(false);
    });
    return unsub;
  }, [filter, user]);

  return (
    <div className="">
      <h1 className="text-[22px] font-bold mb-[15px] text-[#4e4e4e]">
        Danh sách khoản vay
      </h1>
      <div className="grid grid-cols-2 gap-[15px] my-[15px]">
        <div className="bg-white py-[10px] px-[15px]">
          <h1 className="text-[22px] mb-[10px] font-bold text-[#4e4e4e]">
            {/* 144,969,116,700 VND */}
            {formatMoney(totalLoan)} VND
          </h1>
          <p className="text-[13px] mb-[5px]">Tổng khoản vay</p>
        </div>
        <div className="bg-white py-[10px] px-[15px]">
          <h1 className="text-[22px] mb-[10px] font-bold text-[#4e4e4e]">
            {formatMoney(totalApprovedLoan)} VND
          </h1>
          <p className="text-[13px] mb-[5px]">Tiền rút</p>
        </div>
      </div>
      <SearchLoans listLoan={loans} onSearch={onSearch} />
      <TableLoans listLoan={loans} loading={loading} />
    </div>
  );
};

export default Loanslist;

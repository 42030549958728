import React from "react";
import { Form, Input, Button, Switch } from "antd";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../../store";
import { ModalProjectAction } from "../../../../../store/Managermodal";
interface FeeDrop {
  id: string;
  editData: any;
}
const Fee: React.FC<FeeDrop> = ({ id, editData }) => {
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();

  const onFinish = (value: object) => {
    console.log(value);
    dispatch(ModalProjectAction.CLOSE_MODAL(''))
  };
  return (
    <div className="border-t pt-[20px]">
      <p className="font-[500] my-[8px]">
        Họ tên: <span className="font-[400]">{ editData.fullName }</span>
      </p>
      <p className="font-[500] my-[8px]">
        SĐT: <span className="font-[400]">{ editData.phoneNumber }</span>
      </p>
      <Form name="form_item_path" layout="vertical" onFinish={onFinish}>
        <Form.Item
          label=<div className="font-bold mt-[10px]">Khoản phí</div>
          name="fee"
        >
          <Input placeholder="Khoản phí" />
        </Form.Item>
        <Form.Item
          label=<div className="font-bold mt-[10px]">Ghi chú</div>
          name="note"
        >
          <Input placeholder="Ghi chú" />
        </Form.Item>

        <div className="flex gap-[15px] justify-end mt-[40px] border-t pt-[10px]">
          <button className="p-[5px]  w-[80px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]"
            onClick={() => dispatch(ModalProjectAction.CLOSE_MODAL(''))}
          >
            {t("Cancle")}
          </button>
          <button className="p-[5px]  w-[80px] min-w-[40px]  bg-[#0cc77f]  text-[#fff] duration-300 hover:text-yellow-300  hover:bg-[#0a965b]">
            {t("Addmoney")}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default Fee;

import React, { useEffect } from "react";
import { Form, Input, Button, Switch, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../../store";
import { ModalProjectAction } from "../../../../../store/Managermodal";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../../../config/firebase.config";
import { useForm } from "antd/es/form/Form";
interface UpdateBank {
  id: string;
  contractRef: any;
  contract: any;
}
const UpdateBank: React.FC<UpdateBank> = ({ id, contractRef, contract }) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue(contract);
  }, [contract, form]);

  const onFinish = (value: object) => {
    setLoading(true);
    // const docRef = doc(db, "loan-list", contract.id);
    
    updateDoc(contractRef, value).then(() => {
      window.location.reload();
    }).catch((error) => {
      console.log(error);
      setLoading(false);
    });
  };

  if (loading) return (
    <div className="py-[60px] flex justify-center items-center">
      <Spin />
    </div>
  )
  return (
    <div className="border-t pt-[20px]">
      <Form name="form_item_path" layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item
          name="numberBank"
          label=<div className="font-bold mt-[10px]">Số thẻ ngân hàng</div>
        >
          <Input placeholder="Số thẻ ngân hàng" />
        </Form.Item>
        <Form.Item
          name="nameAccount"
          label=<div className="font-bold mt-[10px]">Tên chủ tài khoản</div>
        >
          <Input placeholder="Tên chủ tài khoản" />
        </Form.Item>
        <Form.Item
          name="nameBank"
          label=<div className="font-bold mt-[10px]">Tên ngân hàng</div>
        >
          <Input placeholder="Tên ngân hàng" />
        </Form.Item>

        <div className="flex gap-[15px] justify-end mt-[40px] border-t pt-[10px]">
          <button className="p-[5px]  w-[80px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]"
            onClick={() => dispatch(ModalProjectAction.CLOSE_MODAL(''))}
          >
            {t("Cancle")}
          </button>
          <button className="p-[5px]  w-[80px] min-w-[40px]  bg-[#0cc77f]  text-[#fff] duration-300 hover:text-yellow-300  hover:bg-[#0a965b]">
            Cập nhật
          </button>
        </div>
      </Form>
    </div>
  );
};

export default UpdateBank;

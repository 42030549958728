import React, { useState } from 'react'
import { useAppDispatch } from '../../../../../../../store';
import { ModalProjectAction } from '../../../../../../../store/Managermodal';
import { collection, doc, getDocs, updateDoc, where } from 'firebase/firestore';
import { db } from '../../../../../../../config/firebase.config';
import { query } from 'firebase/database';

export default function BrowserWithDraw({ data }: { data: any }) {

    const [isTitle, setIsTitle] = useState<any>('');
    const [isContent, setIsContent] = useState<any>('');

    const dispath = useAppDispatch();

    const handleBrowserWithDraw = async () => {
        const querySnapshot = await getDocs(collection(db, "withdraw-money"));

        querySnapshot.forEach(async (snapshot) => {

            if (snapshot.data().uuid === data?.uuid) {

                const docRef = doc(db, "withdraw-money", snapshot.id);

                await updateDoc(docRef, {
                    status: "accept",
                    title: isContent,
                    content: isContent,
                });

                window.location.reload();

            }

        });

        dispath(ModalProjectAction.CLOSE_MODAL(''));
    }

    return (
        <div>
            <h1 className='text-base mt-4'>Bạn có đồng ý duyệt khoản vay này không</h1>
            <div className='w-full flex flex-col gap-2 mt-4'>
                <div className='w-full'>
                    <h1>Tiêu đề</h1>
                    <textarea className='rounded-lg border p-1 border-gray-200 outline-none w-full'
                        onChange={(e) => setIsTitle(e.target.value)}
                        value={isTitle}
                    ></textarea>
                </div>
                <div className='w-full'>
                    <h1>Nội dung duyệt</h1>
                    <textarea className='rounded-lg p-1 border border-gray-200 outline-none w-full'
                        onChange={(e) => setIsContent(e.target.value)}
                        value={isContent}
                    ></textarea>
                </div>
                <div className='w-full flex gap-4 justify-end items-center'>
                    <button className='py-2 px-8 rounded-lg bg-white border border-gray-200 '
                        onClick={() => dispath(ModalProjectAction.CLOSE_MODAL(''))}
                    >Hủy</button>
                    <button className='py-2 px-8 rounded-lg bg-red-500 text-white'
                        onClick={() => handleBrowserWithDraw()}
                    >Duyệt</button>
                </div>
            </div>
        </div>
    )
}

import React, { useEffect, useState } from "react";
import SearchStaff from "./Components/SearchStaff";
import TableStaff from "./Components/TableStaff";
import { useAppDispatch } from "../../store";
import { ModalProjectAction } from "../../store/Managermodal";
import Add from "./Components/Modal/Add";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../config/firebase.config";

function removeTones(str: string) {
  // remove accents
  var from = "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ",
    to = "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(RegExp(from[i], "gi"), to[i]);
  }

  str = str.toLowerCase()
    .trim();
  // .replace(/[^a-z0-9\-]/g, '-')
  // .replace(/-+/g, '-');

  return str;
}

const Staffsale = () => {
  const AppDispath = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<any[]>([]);
  const [filter, setFilter] = useState({
    phoneNumber: "",
    nameAccount: "",
    status: ""
  });

  const onSearch = async (searchData: any) => {
    setLoading(true);
    setFilter(searchData);
  }

  useEffect(() => {
    const collectionRef = collection(db, "admins");
    const unsub = onSnapshot(collectionRef, (snapshot) => {
      let users: any[] = [];
      let index = 0;
      snapshot.forEach((doc) => {
        const data = doc.data();

        if (filter.nameAccount) {
          const filterName = removeTones(filter.nameAccount);
          const fullName = removeTones(data.nameAccount);
          if (!fullName.includes(filterName)) return;
        }

        if (filter.phoneNumber) {
          if (!data.phoneNumber.includes(filter.phoneNumber)) return;
        }

        if (filter.status) {
          if (!data.status.includes(filter.status)) return;
        }

        data.key = index + 1;
        users.push({ ...data, id: doc.id });
        index++;
      });
      setUsers(users);
      setLoading(false);
    });
    return unsub;
  }, [filter]);

  return (
    <div className="ml-[30px]">
      <div className="flex flex-row justify-between">
        <h1 className="text-[22px] font-bold mb-[15px] text-[#4e4e4e]">
          Danh sách nhân viên
        </h1>
        <div>
          <button
            onClick={() => {
              AppDispath(
                ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                  content: <Add />,
                  title: "Thêm mới người dùng",
                  width: "500px",
                })
              );
            }}
            className="bg-[#0cc77f] rounded-[10px] h-[30px] text-[15px] mr-[9px] text-white px-[25px]  duration-300 hover:font-bold hover:bg-[#288763]"
          >
            Thêm mới
          </button>
        </div>
      </div>

      <SearchStaff onSearch={onSearch} />
      <TableStaff data={users} loading={loading}/>
    </div>
  );
};

export default Staffsale;

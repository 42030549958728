import { Spin, Tag } from "antd";
import { doc, getDoc } from "firebase/firestore";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { db } from "../../../../../config/firebase.config";
import { useAppDispatch } from "../../../../../store";
import { ModalProjectAction } from "../../../../../store/Managermodal";
import AllowDraw from "../AllowWithDraw";
import LookWallet from "../LookWallet";
import UpdateBalance from "../UpdateBalance";
import UpdateWalletPw from "../UpdateWalletPassWord";

export default function BalanceInfomation({ uid, accountData }: any) {

    const AppDispath = useAppDispatch();
    const [isBalance, setIsBalance] = useState<any | null>();

    const getBalance = async () => {
        const docRef = doc(db, "balance", uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setIsBalance(docSnap.data())
        }else{
          setIsBalance({});
        }
    }

    useEffect(() => {
        getBalance();
    }, []);

    const formatMoney = (number: number) => {
        number = Math.round(number * 1000) / 1000;

        return number
            .toFixed(0)
            .replace(/\D/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div className="bg-white w-full p-[10px] rounded-[10px] mb-[15px]">
            {
                isBalance ? (
                    <>
                        <p className="font-[500] my-[8px]"
                        >
                            {t("Balance")}:{" "}
                            <span className="font-[500] text-[20px] text-[#1890ff]">{formatMoney(accountData?.balance || 0)}</span>
                        </p>
                        <p className="font-[500] my-[8px]">
                            {t("totalFee")}: <span className="font-[400]">{formatMoney(accountData?.totalFee || 0)}</span>
                        </p>
                        <p className="font-[500] my-[8px]">
                            {t("WithdrawStatus")}:{" "}
                            {
                                isBalance?.withdrawalStatus === 'withdrawal' ? <Tag className="text-[13px]" color="#87d068">
                                    Được phép rút tiền
                                </Tag> : <Tag className="text-[13px]" bordered={false} color="error">
                                    Từ chối rút tiền
                                </Tag>
                            }
                        </p>
                        <p className="font-[500] my-[8px]">
                            {t("WalletStatus")}:{" "}
                            {
                                isBalance?.status === 'unactive' ? <Tag className="text-[13px]" color="error">
                                    Đã khóa ví
                                </Tag> : <Tag className="text-[13px]" bordered={false} color="success">
                                    Hoạt động
                                </Tag>
                            }
                        </p>
                        <p className="font-[500] my-[8px]">
                            {t("WalletPassword")}:{" "}
                            <Tag className="text-[13px]" color="default">
                              {
                                accountData?.walletPassword || "Không có mật khẩu"
                              }
                            </Tag>
                        </p>
                        <button
                            onClick={() => {
                                AppDispath(
                                    ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                                        content: <UpdateBalance id={uid} balance={accountData?.balance || 0}/>,
                                        title: <>Cập nhật số dư</>,
                                        width: "500px",
                                    })
                                );
                            }}
                            className="p-[5px] mb-[5px] w-full  bg-[#1890ff] border text-[#fff] duration-300 hover:text-[#078ddb] hover:bg-[#fff] hover:border-[#078ddb] rounded-[10px]"
                        >
                            {t("btnUpdateBalance")}
                        </button>
                        <button
                            onClick={() => {
                                AppDispath(
                                    ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                                        content: <AllowDraw id={uid} withdrawal={isBalance?.withdrawalStatus} />,
                                        title: <>Cho phép rút vay</>,
                                        width: "500px",
                                    })
                                );
                            }}
                            className={`p-[5px] mb-[5px] w-full  border text-[#fff] duration-300  rounded-[10px] ${isBalance?.withdrawalStatus === 'withdrawal' ? 'bg-[#cc3030] hover:text-[#078ddb] hover:bg-[#fff] hover:border-[#078ddb]' : 'bg-[#12ce0c] hover:bg-[#ffffff] border-[#ce290c00] hover:text-[#12ce0c] hover:border-[#12ce0c]'}`}
                        >
                            {
                                isBalance?.withdrawalStatus === 'withdrawal' ? 'Không cho rút tiền' : 'Cho phép rút tiền'
                            }
                        </button>
                        <button
                            onClick={() => {
                                AppDispath(
                                    ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                                        content: <LookWallet id={uid} statusWallet={isBalance?.status} />,
                                        title: <>Cho phép rút vay</>,
                                        width: "500px",
                                    })
                                );
                            }}
                            className={`p-[5px] mb-[5px] w-full   border text-[#fff] duration-300 rounded-[10px] ${isBalance?.status === 'active' ? 'bg-[#ce290c] hover:bg-[#ad1d03] ' : 'bg-[#39ce0c] hover:bg-[#39ce0c] '}`}

                        >
                            {
                                isBalance?.status === 'active' ? 'Khóa ví' : 'Mở khóa ví'
                            }
                        </button>
                        <button
                            onClick={() => {
                                AppDispath(
                                    ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                                        content: <UpdateWalletPw id={uid} />,
                                        title: <>Cập nhật mật khẩu ví</>,
                                        width: "500px",
                                    })
                                );
                            }}
                            className="p-[5px] mb-[5px] w-full  bg-[#757575] border text-[#fff] duration-300 hover:text-[#078ddb] hover:bg-[#fff] hover:border-[#078ddb] rounded-[10px]"
                        >
                            {t("btnUpdateWalletPw")}
                        </button>
                    </>
                ) : (
                    <div className="h-[20vh] flex justify-center items-center">
                        <Spin />
                    </div>
                )
            }
        </div>
    )
}

import { collection, doc, getDocs, where } from 'firebase/firestore';
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { db } from '../../../../../config/firebase.config';
import { query } from 'firebase/database';
import { Empty, Tag } from 'antd';
import { useAppDispatch } from '../../../../../store';
import { ModalProjectAction } from '../../../../../store/Managermodal';
import BrowserWithDraw from './components/BrowserWithDraw';
import RejectWithDraw from './components/RejectWithDraw';

export default function WithDraw({ uid }: { uid: string }) {

    const [isWithDraw, setIsWithDraw] = useState<any | null>([]);
    const [loading, setLoading] = useState(false);
    const AppDispath = useAppDispatch();

    function formatMoney(number: number) {
        number = Math.round(number * 1000) / 1000;

        return number
            .toFixed(0)
            .replace(/\D/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    const getIsWithDraw = async () => {
        setLoading(true);
        const contractRef = collection(db, "withdraw-money");
        const querySnapshot = await getDocs(contractRef);

        const contract: any[] = [];

        querySnapshot.forEach((doc) => {
            if (doc.data().uid === uid) {
                contract.push(doc.data());
            }
        });


        setIsWithDraw(contract.reverse());
        setLoading(false);
    }

    useEffect(() => {
        getIsWithDraw();
    }, []);


    return (
        <div className='min-h-[20vh] w-full'>
            <h1>{t("Withdraw")}</h1>
            <div className='mt-4 flex flex-col gap-2'>
                {

                    isWithDraw?.length > 0 ? isWithDraw.map((item: any, index: any) => (
                        <div className='flex w-full justify-between items-center' key={index}>
                            <div className='mt-2 flex flex-col gap-2'>
                                <div><span>Số tiền:</span> {formatMoney(item?.moneyAdd)} VND</div>
                                <div><span>Ngày giao dịch</span> {item?.time} </div>
                                <div>
                                    <span>Trạng thái: </span>
                                    {
                                        item?.status === 'pending' ? <Tag color='yellow'>Đang chờ</Tag> : item?.status === 'reject' ? <Tag color='red'>Đã hủy</Tag> : <Tag color='green'>Đã duyệt</Tag>
                                    }
                                </div>
                                <div><span>Nội dung duyệt:</span> {item?.content}</div>
                            </div>
                            <div className='flex flex-col items-end gap-2 flex-1'>
                                {
                                    item?.status === 'pending' ? (
                                        <>
                                            <button
                                                onClick={() => {
                                                    AppDispath(
                                                        ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                                                            content: <BrowserWithDraw data={item} />,
                                                            title: <>Cập nhật khoản vay</>,
                                                            width: "500px",
                                                        })
                                                    );
                                                }}
                                                className="py-[0.3rem] w-full max-w-[20rem] duration-150 hover:bg-white border border-transparent hover:border-gray-500 bg-red-500 hover:text-gray-500 rounded-lg  text-white"
                                            >
                                                Duyệt
                                            </button>
                                            <button
                                                onClick={() => {
                                                    AppDispath(
                                                        ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                                                            content: <RejectWithDraw data={item} />,
                                                            title: <>Từ chối</>,
                                                            width: "500px",
                                                        })
                                                    );
                                                }}
                                                className="py-[0.3rem] w-full max-w-[20rem] duration-150 hover:bg-white border border-transparent bg-blue-500 hover:border-gray-500 hover:text-gray-500 rounded-lg  text-white"
                                            >
                                                Từ chối
                                            </button>
                                        </>
                                    ) : (
                                        <button
                                            className="py-[0.3rem] w-full max-w-[20rem] duration-150 hover:bg-white border border-transparent bg-gray-500 hover:border-gray-500 hover:text-gray-500 rounded-lg  text-white"
                                        >
                                            Chỉnh sửa thông tin
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                    )) : <Empty />
                }
            </div>
        </div>
    )
}

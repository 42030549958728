import { Collapse, Form, Input, Select, TreeSelect } from "antd";
import { Option } from "antd/es/mentions";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const SearchStaff = ({ onSearch }: any) => {
  const { t, i18n } = useTranslation();
  const onFinish = (value: object) => {
    onSearch(value);
  };
  // DATA TREE
  const treeData = [
    {
      value: "active",
      title: "Hoạt động",
    },
    {
      value: "block",
      title: "Ngưng hoạt động",
    },
  ];
  // TREE
  const [valueTree, setValueTree] = useState("active");
  const onChangeTree = (newValue: string) => {
    setValueTree(newValue);
  };

  const searchItem = () => {
    return (
      <Form
        className="mt-[20px] mb-[20px]"
        name="form_item_path"
        layout="vertical"
        onFinish={onFinish}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[15px]">
          <Form.Item
            name="phoneNumber"
            label=<div className="font-bold">{t("Phone")}</div>
          >
            <Input placeholder={t("Phone")} />
          </Form.Item>
          <Form.Item
            name="nameAccount"
            label=<div className="font-bold">{t("Name")}</div>
          >
            <Input placeholder={t("Name")} />
          </Form.Item>
          <Form.Item
            name="status"
            label=<div className="font-bold">{t("Status")}</div>
          >
            <Select onChange={onChangeTree} placeholder={t("Select status")}>
              {treeData.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Form.Item label=<div className="font-bold">{t("Action")}</div>>
          <button className="p-[5px]  w-[80px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]">
            {t("Search")}
          </button>
        </Form.Item>
      </Form>
    );
  };
  return (
    <Collapse
      expandIconPosition="end"
      className="mt-[30px] mb-[20px] bg-[#fafafa]"
      items={[
        {
          key: "1",
          label: <>{t("FilterSearch")} (0)</>,
          children: searchItem(),
        },
      ]}
    />
  );
};

export default SearchStaff;

import { Form, Input, Spin, TreeSelect } from "antd";
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useState } from "react";
import toast from "react-hot-toast";
import { db } from "../../../../../config/firebase.config";
import { useAppDispatch } from "../../../../../store";
import { ModalProjectAction } from "../../../../../store/Managermodal";


const Add = () => {

  const dispatch = useAppDispatch();
  const [isLoad, setIsLoad] = useState<boolean>(false);

  const createNewUser = async (value: any) => {
    const auth = getAuth();

    // i want to create new user but i don't want this account save in auth
    // because i want to use this account to login admin
    // so i will create new user in collection admins
    // and then i will login with this account

    return new Promise(async (resolve, reject) => {

      setIsLoad(true);

      let data = {
        ...value,
        nameAccount: `${value.nameAccount}@gmail.com`
      }


      const user = await createUserWithEmailAndPassword(auth, data.nameAccount, data.password);

      const adminRef = doc(db, "admins", user.user.uid);

      await setDoc(adminRef, {
        uid: user.user.uid,
        status: data.status,
        fullName: data.fullName,
        socialNetwork: data.socialNetwork,
        phoneNumber: data.phoneNumber,
        nameAccount: value.nameAccount
      }).then(() => {
        setIsLoad(false);
        dispatch(ModalProjectAction.CLOSE_MODAL(''));
      }).catch((error) => {
        setIsLoad(false);
        toast.error("Có lỗi xảy ra, vui lòng thử lại sau");
      });

      auth.signOut();

      resolve(true);

    })


  }

  const results = async (value: any) => {
    const newUser = await createNewUser(value);

    return newUser;
  }

  const onFinish = async (value: any) => {

    const result = await results(value);

    const auth = getAuth();

    await signInWithEmailAndPassword(
      auth,
      `admin@gmail.com`,
      '123456'
    );

    if (result) {
      toast.success("Thêm tài khoản thành công");
    } else {
      toast.error("Có lỗi xảy ra, vui lòng thử lại sau");
    }
  };

  // DATA TREE
  const treeData = [
    {
      value: "active",
      title: "Hoạt động",
    },
    {
      value: "block",
      title: "Ngưng hoạt động",
    },
  ];
  // TREE
  const [valueTree, setValueTree] = useState<string>("Hoạt động");
  const onChangeTree = (newValue: string) => {
    setValueTree(newValue);
  };
  // DATA TREE
  const UserData = [
    {
      value: "0",
      title: "Chọn",
    },
    {
      value: "1",
      title: "Người dùng",
    },
    {
      value: "2",
      title: "Cộng tác viên",
    },
  ];
  // TREE
  const [valueUser, setValueUser] = useState<string>("Chọn");
  const onChangeUser = (newValue: string) => {
    setValueUser(newValue);
  };

  if (isLoad) return (
    <div className="border-t pt-[20px] h-[30vh] flex justify-center items-center">
      <Spin />
    </div>
  )

  return (
    <div className="border-t pt-[20px]">
      <Form name="form_item_path" layout="vertical" onFinish={onFinish}>
        <Form.Item
          name={"nameAccount"}
          label=<div className="font-bold mt-[10px]">Tên đăng nhập</div>
        >
          <Input placeholder="Tên đăng nhập" />
        </Form.Item>
        <Form.Item name={"password"} label=<div className="font-bold mt-[10px]">Mật khẩu</div>>
          <Input placeholder="Mật khẩu" type="password" min={6} required />
        </Form.Item >
        <Form.Item name="status" label=<div className="font-bold mt-[10px]">Trạng thái</div>>
          <TreeSelect
            style={{ width: "100%" }}
            value={valueTree}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            treeData={treeData}
            treeDefaultExpandAll
            onChange={onChangeTree}
          />
        </Form.Item>
        <Form.Item name="fullName" label=<div className="font-bold mt-[10px]">Tên</div>>
          <Input placeholder="Tên" />
        </Form.Item>
        <Form.Item name="socialNetwork" label=<div className="font-bold mt-[10px]">Link FB/Zalo/Telegram</div>>
          <Input placeholder="Link FB/Zalo/Telegram" />
        </Form.Item>
        <Form.Item name="phoneNumber" label=<div className="font-bold mt-[10px]">SĐT</div>>
          <Input placeholder="SĐT" />
        </Form.Item>

        <div className="flex gap-[15px] justify-end mt-[40px] border-t pt-[10px]">
          <button className="p-[5px]  w-[80px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]"
            onClick={() => dispatch(ModalProjectAction.CLOSE_MODAL(''))}
          >
            Hủy
          </button>
          <button className="p-[5px]  w-[80px] min-w-[40px]  bg-[#0cc77f]  text-[#fff] duration-300 hover:text-yellow-300  hover:bg-[#0a965b]">
            Xác nhận
          </button>
        </div>
      </Form >
    </div >
  );
};

export default Add;

import { Collapse, Form, Input, Select } from "antd";
import { Option } from "antd/es/mentions";
import { useTranslation } from "react-i18next";
const SearchOverviews = ({ onSearch }: any) => {
  const { t, i18n } = useTranslation();

  const onFinish = (value: object) => {
    onSearch({
      value,
    });
  };
  const statusTime = [
    {
      value: "-1",
      title: "Tất cả",
    },
    {
      value: "new",
      title: "Người dùng mới",
    },
    {
      value: "",
      title: "Đang KYC",
    },
    {
      value: "active",
      title: "Đã tạo HĐ vay",
    },
    {
      value: "3",
      title: "HĐ vay từ chối",
    },
    {
      value: "4",
      title: "HĐ vay được duyệt",
    },
    {
      value: "5",
      title: "Cho phép rút tiền",
    },
    {
      value: "6",
      title: "Rút tiền",
    }
  ];

  const searchItem = () => {
    return (
      <Form
        className="mt-[20px] mb-[20px]"
        name="form_item_path"
        layout="vertical"
        onFinish={onFinish}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-[15px]">
          <Form.Item
            name="phone"
            label=<div className="font-bold">{t("Phone")}</div>
          >
            <Input placeholder={t("Phone")} />
          </Form.Item>
          <Form.Item
            name="name"
            label=<div className="font-bold">{t("Name")}</div>
          >
            <Input placeholder={t("Name")} />
          </Form.Item>
          <Form.Item
            label=<div className="font-bold">{t("Identification")}</div>
            name="xacThuc"
          >
            <Input placeholder={t("Identification")} />
          </Form.Item>
          <Form.Item
            name="trangThai"
            label={<div className="font-bold">{t("Thời gian")}</div>}
          >
            <Select placeholder={t("Thời gian")}>
              {statusTime.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Form.Item label=<div className="font-bold">{t("Action")}</div>>
          <button className="p-[5px]  w-[80px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]">
            {t("Search")}
          </button>
        </Form.Item>
      </Form>
    );
  };
  return (
    <Collapse
      expandIconPosition="end"
      className="mt-[30px] mb-[20px] bg-[#fafafa] rounded-[0px]"
      items={[
        {
          key: "1",
          label: <>{t("FilterSearch")} (0)</>,
          children: searchItem(),
        },
      ]}
    />
  );
};

export default SearchOverviews;

import { useRoutes, Navigate } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import Overview from "../pages/Overview";
import Staffsale from "../pages/Staffsale";
import Totalfee from "../pages/Totalfee";
import Setting from "../pages/Setting";
import Login from "../pages/Login";
import Notfound from "../pages/Notfound";
import Loanslist from "../pages/LoansList";
import General from "../pages/Setting/Components/General";
import Contact from "../pages/Setting/Components/Contact";
import Debit from "../pages/Setting/Components/Debit";
import Bank from "../pages/Setting/Components/Bank";
import Remind from "../pages/Setting/Components/Remind";
import Banner from "../pages/Setting/Components/Banner";
import DetailOverViews from "../pages/Overview/DetailOverview";
import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from "../config/firebase.config";

const Router = (): JSX.Element | null => {
  const [user, setUser] = useState<any>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(db.app), (userAuth) => {
      // Check if a user is signed in or signed out
      if (userAuth) {
        // User is signed in
        setUser(userAuth);
      } else {
        if(window.location.pathname !== "/login") {
          window.location.href = "/login"
        }
      }
    });

    // Clean up the subscription to avoid memory leaks
    return () => unsubscribe();
  }, []);

  const element = useRoutes([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <Overview />,
        },
        {
          path: "/user",
          element: <Overview />,
        },
        {
          path: "/user/detail/:id",
          element: <DetailOverViews />,
        },
        {
          path: "/staff-sale",
          element: <Staffsale />,
        },
        {
          path: "/loans-list",
          element: <Loanslist />,
        },
        {
          path: "/total-fee",
          element: <Totalfee />,
        },
        {
          path: "/setting",
          element: <Setting />,
          children: [
            {
              path: "/setting",
              element: <General />,
            },
            {
              path: "/setting/contact",
              element: <Contact />,
            },
            {
              path: "/setting/debit",
              element: <Debit />,
            },
            {
              path: "/setting/bank",
              element: <Bank />,
            },
            {
              path: "/setting/remind",
              element: <Remind />,
            },
            {
              path: "/setting/banner",
              element: <Banner />,
            },
          ]
        }
      ],
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "*",
      element: <Navigate to="/404" />,
    },
    {
      path: "/404",
      element: <Notfound />,
    },
  ]);
  return element;
};
export default Router;

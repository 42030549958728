import React from "react";
import { useAppDispatch } from "../../../../../store";
import { collection, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../../../../../config/firebase.config";
import toast from "react-hot-toast";
import { Spin } from "antd";
import { ModalProjectAction } from "../../../../../store/Managermodal";
import { t } from "i18next";

export default function RefuseTheContract({ uid }: { uid: string }) {

    const dispatch = useAppDispatch()
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleApproveContract = async () => {
        setLoading(true);

        const citiesRef = collection(db, "loan-list");
        const q = await getDocs(citiesRef);
        q.forEach(async (doc) => {
            if (doc.data().uid === uid) {
                await updateDoc(doc.ref, {
                    status: 'rejected'
                }).then(() => {
                    setLoading(false);
                    dispatch(ModalProjectAction.CLOSE_MODAL(''));
                }).catch((error) => {
                    setLoading(false);
                    toast.error("Có lỗi xảy ra, vui lòng thử lại sau");
                });
            }
        });

    }

    if (loading) return (
        <div className="py-[60px] flex justify-center items-center">
            <Spin />
        </div>
    )

    return (
        <div>
            <div className="py-[30px] text-center">
                <p className="flex items-center gap-[20px] justify-center">
                    <svg
                        className="text-yellow-500 text-[26px]"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="exclamation-circle"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                        <path d="M464 688a48 48 0 1096 0 48 48 0 10-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"></path>
                    </svg>{" "}
                    <span className="font-bold text-[16px]">Xác nhận từ chối hợp đồng này</span>
                </p>
                <p>Bạn có chắc chắn thay đổi trạng thái của hợp đồng thành: <span className="font-bold">Đã từ chối</span></p>
            </div>
            <div className="flex gap-[15px] justify-end mt-[40px]">
                <button className="p-[5px]  w-[80px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]"
                    onClick={() => dispatch(ModalProjectAction.CLOSE_MODAL(''))}
                >
                    {t("Cancle")}
                </button>
                <button className="p-[5px] w-[80px] min-w-[40px]  bg-[#1890ff] border text-[#fff] duration-300 hover:text-[#078ddb] hover:bg-[#fff] hover:border-[#078ddb]"
                    onClick={() => handleApproveContract()}
                >
                    {t("Comfirm")}
                </button>
            </div>
        </div>
    )
}

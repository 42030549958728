import React, { useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import type { MenuProps } from "antd";
import { Button, Dropdown, Layout, Menu, Space, TreeSelect, theme } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import IMAGES from "../../asets";
import { useAppDispatch } from "../../store";
import { ModalProjectAction } from "../../store/Managermodal";
import Update from "./Components/Update";
import Views from "./Components/View";
import { useTranslation } from "react-i18next";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { db } from "../../config/firebase.config";
const { Header, Content, Sider } = Layout;
const MainLayout = () => {
  const { t, i18n } = useTranslation();
  const AppDispath = useAppDispatch()
  const [collapsed, setCollapsed] = useState(false);
  const [additionalClassName, setAdditionalClassName] = useState("");
  const [user, setUser] = useState<any>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(db.app), (userAuth) => {
      // Check if a user is signed in or signed out
      if (userAuth) {
        setUser(userAuth);

        console.log(user);
      } 
    });

    // Clean up the subscription to avoid memory leaks
    return () => unsubscribe();
  }, [user]);

  //LANGUEGE
  const LanguegeData = [
    {
      value: "vn",
      title: "🇻🇳  Việt Nam",
    },
    {
      value: "en",
      title: "🇺🇸 English",
    },
    {
      value: "chi",
      title: "🇨🇳 Trung Quốc",
    },
  ];
  const [value, setValue] = useState<string>("🇻🇳 Việt Nam");
  const onChange = (newValue: string) => {
    setValue(newValue);
    i18n.changeLanguage(newValue)
  };
  

 
  // HANDLE ITEM MENU
  type MenuItem = Required<MenuProps>["items"][number];
  function getItem(
    label: React.ReactNode,
    to: string,
    icon?: React.ReactNode,
    children?: MenuItem[]
  ): MenuItem {
    return {
      key: to,
      icon,
      children,
      label: <NavLink to={to}>{label}</NavLink>,
    } as MenuItem;
  }
  //MENU
  const items: MenuItem[] = [
    getItem(
      <>{t('dashboard')}</>,
      "/",
      <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="pie-chart"
        width="1em"
        height="1em"
        fill="currentColor"
        aria-hidden="true"
      >
        <path d="M864 518H506V160c0-4.4-3.6-8-8-8h-26a398.46 398.46 0 00-282.8 117.1 398.19 398.19 0 00-85.7 127.1A397.61 397.61 0 0072 552a398.46 398.46 0 00117.1 282.8c36.7 36.7 79.5 65.6 127.1 85.7A397.61 397.61 0 00472 952a398.46 398.46 0 00282.8-117.1c36.7-36.7 65.6-79.5 85.7-127.1A397.61 397.61 0 00872 552v-26c0-4.4-3.6-8-8-8zM705.7 787.8A331.59 331.59 0 01470.4 884c-88.1-.4-170.9-34.9-233.2-97.2C174.5 724.1 140 640.7 140 552c0-88.7 34.5-172.1 97.2-234.8 54.6-54.6 124.9-87.9 200.8-95.5V586h364.3c-7.7 76.3-41.3 147-96.6 201.8zM952 462.4l-2.6-28.2c-8.5-92.1-49.4-179-115.2-244.6A399.4 399.4 0 00589 74.6L560.7 72c-4.7-.4-8.7 3.2-8.7 7.9V464c0 4.4 3.6 8 8 8l384-1c4.7 0 8.4-4 8-8.6zm-332.2-58.2V147.6a332.24 332.24 0 01166.4 89.8c45.7 45.6 77 103.6 90 166.1l-256.4.7z"></path>
      </svg>
    ),
    getItem(
      <>{t('user')}</>,
      "/user",
      <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="user"
        width="1em"
        height="1em"
        fill="currentColor"
        aria-hidden="true"
      >
        <path d="M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z"></path>
      </svg>
    ),

    getItem(
      <>{t('sales')}</>,
      "/staff-sale",
      <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="usergroup-add"
        width="1em"
        height="1em"
        fill="currentColor"
        aria-hidden="true"
      >
        <path d="M892 772h-80v-80c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v80h-80c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h80v80c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-80h80c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM373.5 498.4c-.9-8.7-1.4-17.5-1.4-26.4 0-15.9 1.5-31.4 4.3-46.5.7-3.6-1.2-7.3-4.5-8.8-13.6-6.1-26.1-14.5-36.9-25.1a127.54 127.54 0 01-38.7-95.4c.9-32.1 13.8-62.6 36.3-85.6 24.7-25.3 57.9-39.1 93.2-38.7 31.9.3 62.7 12.6 86 34.4 7.9 7.4 14.7 15.6 20.4 24.4 2 3.1 5.9 4.4 9.3 3.2 17.6-6.1 36.2-10.4 55.3-12.4 5.6-.6 8.8-6.6 6.3-11.6-32.5-64.3-98.9-108.7-175.7-109.9-110.8-1.7-203.2 89.2-203.2 200 0 62.8 28.9 118.8 74.2 155.5-31.8 14.7-61.1 35-86.5 60.4-54.8 54.7-85.8 126.9-87.8 204a8 8 0 008 8.2h56.1c4.3 0 7.9-3.4 8-7.7 1.9-58 25.4-112.3 66.7-153.5 29.4-29.4 65.4-49.8 104.7-59.7 3.8-1.1 6.4-4.8 5.9-8.8zM824 472c0-109.4-87.9-198.3-196.9-200C516.3 270.3 424 361.2 424 472c0 62.8 29 118.8 74.2 155.5a300.95 300.95 0 00-86.4 60.4C357 742.6 326 814.8 324 891.8a8 8 0 008 8.2h56c4.3 0 7.9-3.4 8-7.7 1.9-58 25.4-112.3 66.7-153.5C505.8 695.7 563 672 624 672c110.4 0 200-89.5 200-200zm-109.5 90.5C690.3 586.7 658.2 600 624 600s-66.3-13.3-90.5-37.5a127.26 127.26 0 01-37.5-91.8c.3-32.8 13.4-64.5 36.3-88 24-24.6 56.1-38.3 90.4-38.7 33.9-.3 66.8 12.9 91 36.6 24.8 24.3 38.4 56.8 38.4 91.4-.1 34.2-13.4 66.3-37.6 90.5z"></path>
      </svg>
    ),
    getItem(
      <>{t('loans')}</>,
      "/loans-list",
      <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="insert-row-above"
        width="1em"
        height="1em"
        fill="currentColor"
        aria-hidden="true"
      >
        <defs>
          <style></style>
        </defs>
        <path d="M878.7 336H145.3c-18.4 0-33.3 14.3-33.3 32v464c0 17.7 14.9 32 33.3 32h733.3c18.4 0 33.3-14.3 33.3-32V368c.1-17.7-14.8-32-33.2-32zM360 792H184V632h176v160zm0-224H184V408h176v160zm240 224H424V632h176v160zm0-224H424V408h176v160zm240 224H664V632h176v160zm0-224H664V408h176v160zm64-408H120c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-80c0-4.4-3.6-8-8-8z"></path>
      </svg>
    ),
    getItem(
      <>{t('toleFee')}</>,
      "/total-fee",
      <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="alert"
        width="1em"
        height="1em"
        fill="currentColor"
        aria-hidden="true"
      >
        <path d="M193 796c0 17.7 14.3 32 32 32h574c17.7 0 32-14.3 32-32V563c0-176.2-142.8-319-319-319S193 386.8 193 563v233zm72-233c0-136.4 110.6-247 247-247s247 110.6 247 247v193H404V585c0-5.5-4.5-10-10-10h-44c-5.5 0-10 4.5-10 10v171h-75V563zm-48.1-252.5l39.6-39.6c3.1-3.1 3.1-8.2 0-11.3l-67.9-67.9a8.03 8.03 0 00-11.3 0l-39.6 39.6a8.03 8.03 0 000 11.3l67.9 67.9c3.1 3.1 8.1 3.1 11.3 0zm669.6-79.2l-39.6-39.6a8.03 8.03 0 00-11.3 0l-67.9 67.9a8.03 8.03 0 000 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l67.9-67.9c3.1-3.2 3.1-8.2 0-11.3zM832 892H192c-17.7 0-32 14.3-32 32v24c0 4.4 3.6 8 8 8h688c4.4 0 8-3.6 8-8v-24c0-17.7-14.3-32-32-32zM484 180h56c4.4 0 8-3.6 8-8V76c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v96c0 4.4 3.6 8 8 8z"></path>
      </svg>
    ),
    getItem(
      <>{t('setting')}</>,
      "/setting",
      <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="setting"
        width="1em"
        height="1em"
        fill="currentColor"
        aria-hidden="true"
      >
        <path d="M924.8 625.7l-65.5-56c3.1-19 4.7-38.4 4.7-57.8s-1.6-38.8-4.7-57.8l65.5-56a32.03 32.03 0 009.3-35.2l-.9-2.6a443.74 443.74 0 00-79.7-137.9l-1.8-2.1a32.12 32.12 0 00-35.1-9.5l-81.3 28.9c-30-24.6-63.5-44-99.7-57.6l-15.7-85a32.05 32.05 0 00-25.8-25.7l-2.7-.5c-52.1-9.4-106.9-9.4-159 0l-2.7.5a32.05 32.05 0 00-25.8 25.7l-15.8 85.4a351.86 351.86 0 00-99 57.4l-81.9-29.1a32 32 0 00-35.1 9.5l-1.8 2.1a446.02 446.02 0 00-79.7 137.9l-.9 2.6c-4.5 12.5-.8 26.5 9.3 35.2l66.3 56.6c-3.1 18.8-4.6 38-4.6 57.1 0 19.2 1.5 38.4 4.6 57.1L99 625.5a32.03 32.03 0 00-9.3 35.2l.9 2.6c18.1 50.4 44.9 96.9 79.7 137.9l1.8 2.1a32.12 32.12 0 0035.1 9.5l81.9-29.1c29.8 24.5 63.1 43.9 99 57.4l15.8 85.4a32.05 32.05 0 0025.8 25.7l2.7.5a449.4 449.4 0 00159 0l2.7-.5a32.05 32.05 0 0025.8-25.7l15.7-85a350 350 0 0099.7-57.6l81.3 28.9a32 32 0 0035.1-9.5l1.8-2.1c34.8-41.1 61.6-87.5 79.7-137.9l.9-2.6c4.5-12.3.8-26.3-9.3-35zM788.3 465.9c2.5 15.1 3.8 30.6 3.8 46.1s-1.3 31-3.8 46.1l-6.6 40.1 74.7 63.9a370.03 370.03 0 01-42.6 73.6L721 702.8l-31.4 25.8c-23.9 19.6-50.5 35-79.3 45.8l-38.1 14.3-17.9 97a377.5 377.5 0 01-85 0l-17.9-97.2-37.8-14.5c-28.5-10.8-55-26.2-78.7-45.7l-31.4-25.9-93.4 33.2c-17-22.9-31.2-47.6-42.6-73.6l75.5-64.5-6.5-40c-2.4-14.9-3.7-30.3-3.7-45.5 0-15.3 1.2-30.6 3.7-45.5l6.5-40-75.5-64.5c11.3-26.1 25.6-50.7 42.6-73.6l93.4 33.2 31.4-25.9c23.7-19.5 50.2-34.9 78.7-45.7l37.9-14.3 17.9-97.2c28.1-3.2 56.8-3.2 85 0l17.9 97 38.1 14.3c28.7 10.8 55.4 26.2 79.3 45.8l31.4 25.8 92.8-32.9c17 22.9 31.2 47.6 42.6 73.6L781.8 426l6.5 39.9zM512 326c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm79.2 255.2A111.6 111.6 0 01512 614c-29.9 0-58-11.7-79.2-32.8A111.6 111.6 0 01400 502c0-29.9 11.7-58 32.8-79.2C454 401.6 482.1 390 512 390c29.9 0 58 11.6 79.2 32.8A111.6 111.6 0 01624 502c0 29.9-11.7 58-32.8 79.2z"></path>
      </svg>
    ),
  ];
  
  //DROPDOWN
  const dropDownData: MenuProps["items"] = [
    {
      label: (
        <p
          onClick={() => {
            AppDispath(
              ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                content: <Views />,
                title: "Thông tin",
                width: "500px",
              })
            );
          }}
        >
          admin
        </p>
      ),
      key: "0",
    },
    {
      label: <p 
      onClick={() => {
        AppDispath(
          ModalProjectAction.OPEN_EDIT_FORM_MODAL({
            content: <Update />,
            title: "Cập nhật người dùng",
            width: "500px",
          })
        );
        }}
      >{t('changePassW')}</p>,
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: <p onClick={async () => {
        await signOut(getAuth(db.app));
        window.location.reload()
      }}>{t('logout')}</p>,
      key: "3",
      
    },
  ];

  // HANDLE MAIN
  const handleMainContent = () => {
    if (!collapsed) {
      setAdditionalClassName("main__toggle");
    } else {
      setAdditionalClassName("");
    }
  };
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        className="z-0"
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        theme="light"
        width={"200px"}
      >
        <Menu
          theme="light"
          defaultSelectedKeys={["1"]}
          mode="inline"
          items={items.filter(i => (
            user?.email !== "admin@gmail.com" &&
            (
              i?.key === "/setting" ||
              i?.key === "/staff-sale"
            ) ? null : i
          ))}
        ></Menu>
      </Sider>
      <Layout className="layout-header">
        <Header style={{ padding: "0px 10px", background: colorBgContainer }}>
          <div className="flex flex-row items-center">
            {collapsed === true ? (
              ""
            ) : (
              <div className="w-[80px] h-[40px] ">
                <img
                  className="w-full h-full"
                  src={`${IMAGES.Logo}`}
                  alt="logo"
                />
              </div>
            )}
            <Button
              className="ml-[26px]"
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => {
                handleMainContent();
                setCollapsed(!collapsed);
              }}
              style={{
                fontSize: "23px",
              }}
            />
            <TreeSelect
              style={{ width: "250px" }}
              value={value}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              treeData={LanguegeData}
              treeDefaultExpandAll
              onChange={onChange}
            />
          </div>
          <div className="flex  w-[100px]">
            <Dropdown menu={{ items: dropDownData }} trigger={["click"]}>
              <a
                className="flex items-center gap-[20px]"
                onClick={(e) => e.preventDefault()}
              >
                <div className="flex mt-[10px] justify-center items-center m-auto rounded-[50%] border-[1px] border-gray-400 w-[26px] h-[26px] overflow-hidden ">
                  <img
                    className="w-full h-[65%] object-cover"
                    src={IMAGES.Imgdefault}
                    alt="user"
                  />
                </div>
                <Space className="font-bold">Admin</Space>
              </a>
            </Dropdown>
          </div>
        </Header>
        <Content
          className={`content__layout__admin mt-[10px] pl-[30px] h-full ${additionalClassName}`}
        >
          <div style={{ width: "calc(100% - 20px)" }}>
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;

import { BrowserRouter } from "react-router-dom";
import Router from "./routers";
import ModalProject from "./components/Modal";

function App() {
  return (
    <BrowserRouter>
      <ModalProject />
      <Router />
    </BrowserRouter>
  );
}

export default App;

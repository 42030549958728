import React from "react";
import { Space, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
// DATA TYPE
interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}
// DATA COL
const columns: ColumnsType<DataType> = [
  {
    title: "ID",
    dataIndex: "key",
    key: "key",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Ngày giao dịch",
    dataIndex: "age",
    key: "age",
  },
  {
    title: "Người dùng",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Nội dung",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Trạng thái",
    dataIndex: "address",
    key: "address",
  }
];

const TableFee = () => {
  const data: DataType[] = [
   
  ];

  return (
    <div className="p-[24px] bg-white">
      <Table columns={columns} dataSource={data} />
    </div>
  );
};

export default TableFee;

import React from "react";
import { Space, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useAppDispatch } from "../../../../store";
import { ModalProjectAction } from "../../../../store/Managermodal";
import Views from "../Modal";
// DATA TYPE
interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

type TableLoansProps = {
  listLoan: any[],
  loading: boolean
};

const TableLoans = ({ listLoan, loading }: TableLoansProps) => {

  const AppDispath = useAppDispatch();

  // Format tiền
  function formatMoney(number: number) {
    number = Math.round(number * 1000) / 1000;

    return number
      .toFixed(0)
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  // DATA COL
  const columns: ColumnsType<DataType> = [
    {
      title: "Mã đơn",
      dataIndex: "idContract",
      key: "id",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Ngày giao dịch",
      dataIndex: "BorrowedTime",
      key: "BorrowedTime",
    },
    {
      title: "Số tiền",
      dataIndex: "loan",
      key: "loan",
      render: (loan) => <p>{formatMoney(loan.loanAmount)}VND</p>,
    },
    {
      title: "Người dùng",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Nội dung",
      dataIndex: "purpose",
      key: "purpose",
    },
    {
      title: "Trạng thái",
      key: "status",
      render: (status, record) => (
        <div className="flex gap-[10px]">
          <span className="bg-[#666699] px-[10px] py-[2px] text-[#fff] text-[12px]">
            Khoản vay
          </span>
          <span className={`bg-[#008000] px-[10px] py-[2px] text-[#fff] text-[12px] ${status.status === 'pending' ? 'bg-[#e1e42b]' : status.status === 'approved' ? 'bg-[#008000]' : 'bg-[#ff0000]'}`}>
            {status.status === 'pending' ? 'Đang chờ' : status.status === 'approved' ? 'Đã duyệt' : 'Từ chối'}
          </span>
        </div>
      ),
    },
    {
      title: "",
      key: "action",
      render: (data, record) => (
        <button
          onClick={() => {
            AppDispath(
              ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                content: <Views data={data} />,
                title: "Thông tin",
                width: "100%",
              })
            );
          }}
          className="p-[5px]  w-[80px] min-w-[40px]  bg-white rounded-[5px] border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]"
        >
          Xem
        </button>
      ),
    },
  ];

  const data: DataType[] = [

  ];


  return (
    <div className="p-[24px] bg-white">
      <Table columns={columns} dataSource={listLoan}
        loading={loading}
      />
    </div>
  );
};

export default TableLoans;

import { Collapse, Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const SearchFee = () => {
  const { t, i18n } = useTranslation();
  const onFinish = (value: object) => {
    console.log(value);
  };
  const searchItem = () => {
    return (
      <Form
        className="mt-[20px] mb-[20px]"
        name="form_item_path"
        layout="vertical"
        onFinish={onFinish}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-[15px]">
          <Form.Item label=<div className="font-bold">{t("Phone")}</div>>
            <Input placeholder={t("Phone")} />
          </Form.Item>
          <Form.Item label=<div className="font-bold">{t("Name")}</div>>
            <Input placeholder={t("Name")} />
          </Form.Item>
          <Form.Item
            label=<div className="font-bold">{t("Identification")}</div>
            name="fullname"
          >
            <Input placeholder={t("Identification")} />
          </Form.Item>
          <Form.Item
            label=<div className="font-bold">{t("Status")}</div>
            name="fullname"
          >
            <Input placeholder={t("Status")} />
          </Form.Item>
        </div>
        <Form.Item label=<div className="font-bold">{t("Action")}</div>>
          <button className="p-[5px]  w-[80px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]">
            {t("Search")}
          </button>
        </Form.Item>
      </Form>
    );
  };
  return (
    <Collapse
      expandIconPosition="end"
      className="mt-[30px] mb-[20px] bg-[#fafafa]"
      items={[
        {
          key: "1",
          label: <>{t("FilterSearch")} (0)</>,
          children: searchItem(),
        },
      ]}
    />
  );
};

export default SearchFee;

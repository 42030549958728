import { Empty, Image, Tag } from "antd";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import IMAGES from "../../../asets";
import { db } from "../../../config/firebase.config";
import { useAppDispatch } from "../../../store";
import { ModalProjectAction } from "../../../store/Managermodal";
import ChangeSales from "./Componets/ChangeSales";
import UpdateBank from "./Componets/UpdateBank";
import UpdateInfor from "./Componets/UpdateInfor";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import UpdateWalletPw from "./Componets/UpdateWalletPassWord";
import ContractInformation from "./Componets/ContractInfomation";
import BalanceInfomation from "./Componets/BalanceInfomation";
import History from "./Componets/History";
import WithDraw from "./Componets/WithDraw";

const DetailOverViews = () => {
  const { t, i18n } = useTranslation();
  const AppDispath = useAppDispatch();

  const { id } = useParams<{ id: string }>()

  const [isAccount, setIsAccount] = useState<any>({})
  const [user, setUser] = useState<any>();
  const [contract, setContract] = useState({});
  const [contractRef, setContractRef] = useState<any>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(db.app), (userAuth) => {
      // Check if a user is signed in or signed out
      if (userAuth) {
        setUser(userAuth);
      }
    });

    // Clean up the subscription to avoid memory leaks
    return () => unsubscribe();
  }, [user]);

  const getDetailAccount = async () => {
    if (id) {
      const docRef = doc(db, "users", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setIsAccount(docSnap.data())
      }

      const contractRef = collection(db, "users");
      const q = query(contractRef, where("uid", "==", id));
      const snapshot = await getDocs(q);
      setContract(snapshot.docs?.[0]?.data() || {});
      setContractRef(snapshot.docs?.[0]?.ref);
    }
  }

  useEffect(() => {
    getDetailAccount();
  }, []);

  return (
    <div className="flex flex-col md:flex-row lg:flex-row gap-4 ">
      <div className="flex flex-col gap-[10px] w-full md:w-[75%] lg:w-[75%]">
        <div className="flex gap-4 bg-white w-full p-[10px] rounded-[10px]">
          <div className="w-[25%] h-[8rem] overflow-hidden">
            <Image className="w-full h-full object-cover" src={isAccount?.image?.imageUrlFront ? isAccount?.image?.imageUrlFront : IMAGES.Imgdefault} />
          </div>
          <div className="w-[25%] h-[8rem] overflow-hidden">
            <Image className="w-full h-full object-cover" src={isAccount?.image?.imageUrlBack ? isAccount?.image?.imageUrlBack : IMAGES.Imgdefault} />
          </div>
          <div className="w-[25%] h-[8rem] overflow-hidden">
            <Image className="w-full h-full object-cover" src={isAccount?.image?.imageUrlFace ? isAccount?.image?.imageUrlFace : IMAGES.Imgdefault} />
          </div>
          <div className="w-[25%] h-[8rem] overflow-hidden">
            <Image className="w-full h-full object-cover" src={isAccount?.signature ? isAccount?.signature : IMAGES.Imgdefault} />
          </div>
        </div>
        <div className="bg-white w-full p-[10px] rounded-[10px]">
          {
            id ? <ContractInformation uid={id} contract={contract} /> : <Empty description={t("NoData")} />
          }
        </div>
        <div className="bg-white w-full p-[10px] rounded-[10px]">
          {
            id ? <History uid={id} /> : <Empty description={t("NoData")} />
          }
        </div>
        <div className="bg-white w-full p-[10px] rounded-[10px]">
          <h1>{t("totalFee")}</h1>
          <Empty description={t("NoData")} />
        </div>
        <div className="bg-white w-full p-[10px] rounded-[10px]">
          {
            id ? <WithDraw uid={id} /> : <Empty description={t("NoData")} />
          }

        </div>
      </div>
      <div className="w-full md:w-[35%] lg:w-[35%]">
        {
          id ? <BalanceInfomation uid={id} accountData={isAccount} /> : <Empty description={t("NoData")} />
        }
        <div className="bg-white w-full p-[10px] rounded-[10px] ">
          <h1 className="text-center font-[500] my-[8px] text-[16px]">
            {t("UserInformation")}
          </h1>
          <p className="font-[500] my-[8px]">
            {t("Name")}: <span className="font-[400]">{isAccount.fullName}</span>
          </p>
          <p className="font-[500] my-[8px]">
            {t("Phone")}: <span className="font-[400]">{isAccount.myPhoneNumber}</span>
          </p>
          <p className="font-[500] my-[8px]">
            {t("birthDay")}: <span className="font-[400]">{isAccount.dateOfBirth}</span>
          </p>
          <p className="font-[500] my-[8px]">
            {t("sex")}: <span className="font-[400]">{isAccount.genre}</span>
          </p>
          <p className="font-[500] my-[8px]">
            {t("cccd")}:{" "}
            <span className="font-[400]">{isAccount.cmnd}</span>
          </p>
          <p className="font-[500] my-[8px]">
            {t("job")}: <span className="font-[400]">{isAccount.job}</span>
          </p>
          <p className="font-[500] my-[8px]">
            {t("salary")}: <span className="font-[400]">
              {isAccount.income === '1' ? 'Dưới 5 triệu' : isAccount.income === '2' ? 'Từ 5 triệu đến 10 triệu' : isAccount.income === '3' ? 'Từ 10 triệu đến 20 triệu' : isAccount.income === '4' ? 'Từ  20 triệu trở lên' : ''}
            </span>
          </p>
          <p className="font-[500] my-[8px]">
            {t("debitReasion")}: <span className="font-[400]">{isAccount.purpose}</span>
          </p>
          <p className="font-[500] my-[8px]">
            {t("relativePhone")}: <span className="font-[400]">{isAccount.phoneNumber}</span>
          </p>
          <p className="font-[500] my-[8px]">
            {t("relativeType")}: <span className="font-[400]">{isAccount.relationship}</span>
          </p>
          <h1 className="text-center font-[500] my-[8px] text-[16px]">
            Ngân hàng
          </h1>
          <p className="font-[500] my-[8px]">
            Số thẻ ngân hàng: <span className="font-[400]">{(contract as any).numberBank}</span>
          </p>
          <p className="font-[500] my-[8px]">
            {t("bankName")}: <span className="font-[400]">{(contract as any).nameBank}</span>
          </p>
          <p className="font-[500] my-[8px]">
            {t("bankOwner")}: <span className="font-[400]">{(contract as any).nameAccount}</span>
          </p>
          <p className="font-[500] my-[8px]">
            SĐT đăng ký: <span className="font-[400]">{(contract as any).phoneNumber}</span>
          </p>
          <h1 className="text-center font-[500] my-[8px] text-[16px]">
            {t("SaleInfo")}
          </h1>
          {
            isAccount.sellerPhone &&
            <p className="font-[500] my-[8px]">
              {t("Name")}: <span className="font-[400]">{isAccount.sellerPhone}</span>
            </p>
          }
          {
            isAccount.sellerName &&
            <p className="font-[500] my-[8px]">
              {t("Phone")}: <span className="font-[400]">{isAccount.sellerName}</span>
            </p>
          }
          {
            user?.email === "admin@gmail.com"
            &&
            <>
              <button
                onClick={() => {
                  AppDispath(
                    ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                      content: <UpdateInfor id={`${id}`} user={isAccount} />,
                      title: <>Chỉnh sửa thông tin</>,
                      width: "500px",
                    })
                  );
                }}
                className="p-[5px] mb-[5px] w-full  bg-[#757575] border text-[#fff] duration-300 hover:text-[#078ddb] hover:bg-[#fff] hover:border-[#078ddb] rounded-[10px]"
              >
                {t("btnUpdateInfo")}
              </button>
              <button
                onClick={() => {
                  AppDispath(
                    ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                      content: <UpdateBank id={`${id}`} contractRef={contractRef} contract={contract} />,
                      title: <>Chỉnh sửa ngân hàng</>,
                      width: "500px",
                    })
                  );
                }}
                className="p-[5px] mb-[5px] w-full  bg-[#1890ff] border text-[#fff] duration-300 hover:text-[#078ddb] hover:bg-[#fff] hover:border-[#078ddb] rounded-[10px]"
              >
                {t("btnUpdateBank")}
              </button>
              <button
                onClick={() => {
                  AppDispath(
                    ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                      content: <ChangeSales id={id || ""} uid={isAccount?.uid} />,
                      title: <>Nhay đổi nhân viên sale</>,
                      width: "500px",
                    })
                  );
                }}
                className="p-[5px] mb-[5px] w-full  bg-[#5d1ca8] border text-[#fff] duration-300 hover:text-[#078ddb] hover:bg-[#fff] hover:border-[#078ddb] rounded-[10px]"
              >
                {t("btnChangeSale")}
              </button>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default DetailOverViews;

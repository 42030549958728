import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Switch, Spin } from "antd";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../../config/firebase.config";
import toast from "react-hot-toast";
interface UpdateWalletPw {
  id: string;
}
const UpdateWalletPw: React.FC<UpdateWalletPw> = ({ id }) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(false);

  const onFinish = async (value: any) => {
    setLoading(true);
    const docRef = doc(db, "users", id);

    await updateDoc(docRef, {
      walletPassword: value.password,
    }).then(() => {
      window.location.reload()
    }).catch((error) => {
      setLoading(false);
      toast.error("Có lỗi xảy ra, vui lòng thử lại sau");
    });
  };

  if (loading) return (
    <div className="py-[60px] flex justify-center items-center">
      <Spin />
    </div>
  )

  return (
    <div>
      <Form name="form_item_path" layout="vertical" onFinish={onFinish}>
        <Form.Item
          label=<div className="font-bold mt-[10px]">Wallet password</div>
          name="password"
        >
          <Input placeholder="Ghi chú" />
        </Form.Item>
        <div className="flex gap-[15px] justify-end mt-[40px] border-t pt-[10px]">
          <button className="p-[5px]  w-[100px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]">
            {t("Cancle")}
          </button>
          <button className="p-[5px]  w-[100px] min-w-[40px]  bg-[#0cc77f]  text-[#fff] duration-300 hover:text-yellow-300  hover:bg-[#0a965b]">
            {t("Comfirm")}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default UpdateWalletPw;

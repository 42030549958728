import { createAsyncThunk } from "@reduxjs/toolkit";
import React from "react";

export const authLogin = createAsyncThunk(
  "auth/adminLogin",
  async (form: any, { rejectWithValue }) => {
    try {
      const res = {
        accessToken: form.accessToken,
        displayName: form.displayName,
        phoneNumber: form.phoneNumber,
        photoURL: form.photoURL,
        email: form.email,
        uid: form.uid,
      };
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

import React, { useEffect, useState } from "react";
import { Form, Input, Button, Switch, TreeSelect, Spin } from "antd";
import { useForm } from "antd/es/form/Form";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../../config/firebase.config";
import { useAppDispatch } from "../../../../../store";
import { ModalProjectAction } from "../../../../../store/Managermodal";

type UpdateDrop = {
  id: string;
  editData: any;
};
const Update: React.FC<UpdateDrop> = ({ id, editData }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const [isLoad, setIsLoad] = React.useState<boolean>(false);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(editData);
  }, [form, id]);

  const onFinish = async (value: object) => {
    setIsLoad(true);
    const userRef = doc(db, "admins", id);

    const data: any = { ...value };
    delete data.password;

    await updateDoc(userRef, data);

    dispatch(ModalProjectAction.CLOSE_MODAL(""));
    window.location.reload();
  };
  // DATA TREE
  const treeData = [
    {
      value: "active",
      title: "Hoạt động",
    },
    {
      value: "block",
      title: "Ngưng hoạt động",
    },
  ];
  // TREE
  const [valueTree, setValueTree] = useState<string>("Hoạt động");
  const onChangeTree = (newValue: string) => {
    setValueTree(newValue);
  };
  // DATA TREE
  const UserData = [
    {
      value: "0",
      title: "Chọn",
    },
    {
      value: "1",
      title: "Người dùng",
    },
    {
      value: "2",
      title: "Cộng tác viên",
    },
  ];
  // TREE
  const [valueUser, setValueUser] = useState<string>("Chọn");
  const onChangeUser = (newValue: string) => {
    setValueUser(newValue);
  };

  if (isLoad) return (
    <div className="py-[60px] flex justify-center items-center">
      <Spin />
    </div>
  )

  return (
    <div className="border-t pt-[20px]">
      <Form name="form_item_path" form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name={"nameAccount"}
          label=<div className="font-bold mt-[10px]">Tên đăng nhập</div>
        >
          <Input placeholder="Tên đăng nhập" disabled />
        </Form.Item>
        <Form.Item
          name={"socialNetwork"}
          label=<div className="font-bold mt-[10px]">Link FB/Zalo/Telegram</div>
        >
          <Input placeholder="Link FB/Zalo/Telegram" />
        </Form.Item>
        <Form.Item name={"phoneNumber"} label=<div className="font-bold mt-[10px]">SĐT</div>>
          <Input placeholder="SĐT" />
        </Form.Item>
        <Form.Item name={"fullName"} label=<div className="font-bold mt-[10px]">Tên</div>>
          <Input placeholder="Tên" />
        </Form.Item>
        <Form.Item name={"status"} label=<div className="font-bold mt-[10px]">Trạng thái</div>>
          <TreeSelect
            style={{ width: "100%" }}
            value={valueTree}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            treeData={treeData}
            treeDefaultExpandAll
            onChange={onChangeTree}
          />
        </Form.Item>

        <div className="flex gap-[15px] justify-end mt-[40px] border-t pt-[10px]">
          <button className="p-[5px]  w-[80px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]"
            onClick={() => {
              dispatch(ModalProjectAction.CLOSE_MODAL(""));
            }}
          >
            Hủy
          </button>
          <button className="p-[5px]  w-[80px] min-w-[40px]  bg-[#0cc77f]  text-[#fff] duration-300 hover:text-yellow-300  hover:bg-[#0a965b]">
            Xác nhận
          </button>
        </div>
      </Form>
    </div>
  );
};

export default Update;

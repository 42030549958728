import { createSlice } from "@reduxjs/toolkit";
import { authLogin } from "./thunkAction";
import { AuthState } from "./auth.type";
import history from "../history";
import { toast } from "react-hot-toast";
// import { toast } from "react-toastify"

const initialState: AuthState = {
    admin: null,
}
export const {
  reducer: managerAuthReducer,
  actions: managerAuthAction,
} = createSlice({
  name: "auth",
  initialState,
  reducers: {
    handleLogout: (state) => {
        state.admin = null
        // Cookies.remove('accessToken')
        history.push('/login')
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(authLogin.fulfilled, (state, action) => {
        // Set cookie
        // Cookies.set('accessToken', action.payload.accessToken, { expires: 7 });

        state.admin = {
            displayName: action.payload.displayName,
            phoneNumber: action.payload.phoneNumber,
            photoURL: action.payload.photoURL,
            email: action.payload.email,
            uid: action.payload.uid,
        }
        toast.success('Đăng nhập thành công')
        history.push('/')
      })
  },
});

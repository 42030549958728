import { Form, Input, Button, Switch, Spin } from "antd";
import ImgCrop from "antd-img-crop";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";

import React, { useEffect, useState } from "react";
import { Upload } from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { auth, db, storage } from "../../../../config/firebase.config";

const Debit: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [formEdit] = Form.useForm();
  const [data, setData] = useState<any[]>([]);


  useEffect(() => {
    const formComtac = data.map((item) => item.formComtac);
    if (formComtac.length > 0) {
      formEdit.setFieldsValue({
        laiSuat: formComtac[0].laiSuat,
        soTienToiThieu: formComtac[0].soTienToiThieu,
        thongBaoKhongDat: formComtac[0].thongBaoKhongDat,
        SoTienToiDa: formComtac[0].SoTienToiDa,
        TbVuotSoTienToiDa: formComtac[0].TbVuotSoTienToiDa,
        SoTienVayMacDinh: formComtac[0].SoTienVayMacDinh,
        thoiHanVayMacDinh: formComtac[0].thoiHanVayMacDinh,
        HienThiDKKhoanVayAo: formComtac[0].HienThiDKKhoanVayAo,
        checkHienThiSo: formComtac[0].checkHienThiSo,
        TBManHinhNguoiDung: formComtac[0].TBManHinhNguoiDung,
        TBManHinhViTinh: formComtac[0].TBManHinhViTinh,
        YcNhapMkTheNH: formComtac[0].YcNhapMkTheNH,
      });
      setLoading(false);
    }
  }, [data, formEdit]);
  useEffect(() => {
    const collectionRef = collection(db, "debit");
    const unsub = onSnapshot(collectionRef, (snapshot) => {
      let datas: any[] = [];
      snapshot.forEach((doc) => {
        datas.push({ ...doc.data(), id: doc.id });
      });
      setLoading(false)
      setData(datas);
    });
    return unsub;
  }, []);
  const onFinish = async (value: object) => {
    const user = auth.currentUser;
    setLoading(true)
    if (user) {
      const userDocRef = doc(db, "debit", user.uid);
      const docSnap = await getDoc(userDocRef);
      if (!docSnap.exists()) {
        await setDoc(userDocRef, {
          formComtac: value,
        });
        setLoading(false)
        alert("Thêm thành cong");
      } else {
        await updateDoc(userDocRef, {
          formComtac: value,
        });
        setLoading(false)
        alert("Update thành cong");
      }
    }
  };
  return (
    <div className="bg-white px-[20px] py-[30px]">
      <Spin spinning={loading}>
        <Form
          form={formEdit}
          autoComplete="off"
          initialValues={{ remember: true }}
          layout="vertical"
          onFinish={onFinish}
          name="form_item_path"
        >
          <Form.Item
            label=<div className="font-bold">Lãi suất</div>
            name="laiSuat"
          >
            <Input placeholder="Lãi suất" />
          </Form.Item>
          <Form.Item
            label=<div className="font-bold">Số tiền tối thiểu</div>
            name="soTienToiThieu"
          >
            <Input placeholder="Số tiền tối thiểur" />
          </Form.Item>
          <Form.Item
            label=<div className="font-bold">
              Thông báo không đạt số tiền tối thiểu
            </div>
            name="thongBaoKhongDat"
          >
            <Input placeholder="Thông báo không đạt số tiền tối thiểu" />
          </Form.Item>
          <Form.Item
            label=<div className="font-bold">Số tiền tối đa</div>
            name="SoTienToiDa"
          >
            <Input placeholder="Số tiền tối đa" />
          </Form.Item>
          <Form.Item
            label=<div className="font-bold">Thông báo vượt số tiền tối đa</div>
            name="TbVuotSoTienToiDa"
          >
            <Input placeholder="Thông báo vượt số tiền tối đa" />
          </Form.Item>
          <Form.Item
            label=<div className="font-bold">Số tiền vay mặc định</div>
            name="SoTienVayMacDinh"
          >
            <Input placeholder="Số tiền vay mặc định" />
          </Form.Item>
          <Form.Item
            label=<div className="font-bold">Thời hạn vay mặc định</div>
            name="thoiHanVayMacDinh"
          >
            <Input placeholder="Thời hạn vay mặc định" />
          </Form.Item>

          <Form.Item
            name="HienThiDKKhoanVayAo"
            label=<div className="font-bold">Hiện thị ĐK khoản vay ảo</div>
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="TBManHinhNguoiDung"
            label=<div className="font-bold">
              Hiện thị thông báo màn hình người dùng
            </div>
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="TBManHinhViTinh"
            label=<div className="font-bold">
              Hiện thị thông báo màn hình ví tiền
            </div>
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="YcNhapMkTheNH"
            label=<div className="font-bold">
              Hiện thị yêu cầu nhập mật khẩu thẻ ngân hàng
            </div>
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <button className="p-[5px]  w-[80px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]">
            Lưu
          </button>
        </Form>
      </Spin>
    </div>
  );
};

export default Debit;

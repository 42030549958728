import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { ModalProjecttReducer } from './Managermodal'
import { managerAuthReducer } from './managerAuth/slice'
export const Store = configureStore({
    reducer: {
        managerModal: ModalProjecttReducer,
        managerAuth: managerAuthReducer
    },
    middleware: getDefaultMiddleware({
        serializableCheck: false, 
    }),
})
// store.dispatch(managerAccountAction.getUser);
export type RootState = ReturnType<typeof Store['getState']>
export type AppDispatch = typeof Store['dispatch']
export const useAppDispatch: () => AppDispatch = useDispatch
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../../../store";
import { ModalProjectAction } from "../../../../store/Managermodal";
import Delete from "../Modal/Delete";
import Fee from "../Modal/Fee";
import Update from "../Modal/Update";

// DATA TYPE
interface DataType {
  numberIndex: number;
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

type TableOverviewProps = {
  listUser: any[];
  loading: boolean;
}

const TableOverview = ({ listUser, loading }: TableOverviewProps) => {
  const { t, i18n } = useTranslation();
  const AppDispath = useAppDispatch();


  // Format tiền
  function formatMoney(number: number) {
    number = Math.round(number * 1000) / 1000;

    return number
      .toFixed(0)
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // DATA COL
  const columns: ColumnsType<any> = [
    {
      title: "Id",
      render: (text, record, index) => (
        <div>
          <p>{index + 1}</p>
        </div>
      )
    },
    {
      title: "Mã khoản vay",
      dataIndex: "idContract",
      key: "idContract",
    },
    {
      title: "Avatar",
      dataIndex: "photoURL",
      key: "photoURL",
      render: (text) => <div className="w-[6rem] h-[5rem] ">
        <img src={text} alt="" className="w-full h-full object-cover" />
      </div>,
    },
    {
      title: <>{t("Information")}</>,
      render: (user) => (
        <div>
          <p><span className="font-semibold">Tên: </span>{user.fullName ? user.fullName : user.myPhoneNumber}</p>
          <p><span className="font-semibold">Số điện thoại: </span>{user.myPhoneNumber}</p>
        </div>
      )
    },
    {
      title: <>{t("Identification")}</>,
      dataIndex: "cmnd",
      key: "cmnd",
    },
    {
      title: <>{t("Employee")}</>,
      dataIndex: "SupportEmployee",
      key: "SupportEmployee",
    },
    {
      title: <>{t("Balance")}</>,
      dataIndex: "loan",
      key: "loan",
      render: (loan) => {
        console.log(loan);
        return <p>{loan ? formatMoney(loan.loanAmount) : '0đ'}</p>
      },
    },
    {
      title: <>{t("Status")}</>,
      dataIndex: "status",
      key: "status",
      render: (status) => <div className="flex flex-col gap-1 items-start text-sm">
        <p className="px-2 py-1 rounded-lg bg-green-400 text-white">Hoạt động</p>
        {status === 'new' ? <p className="px-2 py-1 rounded-lg bg-yellow-400 text-white">Người dùng mới</p> :
          <p className="px-2 py-1 rounded-lg bg-blue-400 text-white">Đã tạo hoạt động</p>}

      </div>,
    },
    {
      title: "",
      dataIndex: "uid",
      key: "uid",
      render: (_, record) => (
        <div className="flex gap-[10px]">
          <button className="p-[5px]  w-[40px] min-w-[40px]  bg-white rounded-[5px] border duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]">
            <Link to={`/user/detail/${(record as any).uid}`}>
              <span role="img" aria-label="eye" className="anticon anticon-eye">
                <svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  data-icon="eye"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path>
                </svg>
              </span>
            </Link>
          </button>
          <button
            onClick={() => {
              AppDispath(
                ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                  content: <Fee id={"123"} editData={record} />,
                  title: <>{t("UpdateUserFee")}</>,
                  width: "500px",
                })
              );
            }}
            className="p-[5px]  w-[40px] min-w-[40px]  bg-[#5600f7] rounded-[5px] text-white  duration-300 hover:bg-white hover:text-[#078ddb]  hover:border-[#078ddb] hover:border"
          >
            <span role="img" aria-label="bug" className="anticon anticon-bug">
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="bug"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M304 280h56c4.4 0 8-3.6 8-8 0-28.3 5.9-53.2 17.1-73.5 10.6-19.4 26-34.8 45.4-45.4C450.9 142 475.7 136 504 136h16c28.3 0 53.2 5.9 73.5 17.1 19.4 10.6 34.8 26 45.4 45.4C650 218.9 656 243.7 656 272c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8 0-40-8.8-76.7-25.9-108.1a184.31 184.31 0 00-74-74C596.7 72.8 560 64 520 64h-16c-40 0-76.7 8.8-108.1 25.9a184.31 184.31 0 00-74 74C304.8 195.3 296 232 296 272c0 4.4 3.6 8 8 8z"></path>
                <path d="M940 512H792V412c76.8 0 139-62.2 139-139 0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8a63 63 0 01-63 63H232a63 63 0 01-63-63c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8 0 76.8 62.2 139 139 139v100H84c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h148v96c0 6.5.2 13 .7 19.3C164.1 728.6 116 796.7 116 876c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8 0-44.2 23.9-82.9 59.6-103.7a273 273 0 0022.7 49c24.3 41.5 59 76.2 100.5 100.5S460.5 960 512 960s99.8-13.9 141.3-38.2a281.38 281.38 0 00123.2-149.5A120 120 0 01836 876c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8 0-79.3-48.1-147.4-116.7-176.7.4-6.4.7-12.8.7-19.3v-96h148c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM716 680c0 36.8-9.7 72-27.8 102.9-17.7 30.3-43 55.6-73.3 73.3C584 874.3 548.8 884 512 884s-72-9.7-102.9-27.8c-30.3-17.7-55.6-43-73.3-73.3A202.75 202.75 0 01308 680V412h408v268z"></path>
              </svg>
            </span>
          </button>
          <button
            onClick={() => {
              AppDispath(
                ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                  content: <Update id={(record as any).uid} editData={record} />,
                  title: <>{t("UpdateUser")}</>,
                  width: "500px",
                })
              );
            }}
            className="p-[5px]  w-[40px] min-w-[40px]  bg-[#078ddb] rounded-[5px] text-white  duration-300 hover:bg-white hover:text-[#078ddb] hover:border-[#078ddb] hover:border"
          >
            <span role="img" aria-label="edit" className="anticon anticon-edit">
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="edit"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 000-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 009.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z"></path>
              </svg>
            </span>
          </button>
          <button
            onClick={() => {
              AppDispath(
                ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                  content: <Delete id={(record as any).uid} />,
                  title: "",
                  width: "500px",
                })
              );
            }}
            className="p-[5px]  w-[40px] min-w-[40px]  bg-red-500 rounded-[5px] text-white  duration-300 hover:bg-red-800   "
          >
            <span
              role="img"
              aria-label="delete"
              className="anticon anticon-delete"
            >
              <svg
                viewBox="64 64 896 896"
                focusable="false"
                data-icon="delete"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true"
              >
                <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
              </svg>
            </span>
          </button>
        </div>
      ),
    },
  ];
  const data: DataType[] = listUser

  return (
    <div className="p-[24px] bg-white">
      <Table columns={columns} dataSource={data} loading={loading} />
    </div>
  );
};

export default TableOverview;

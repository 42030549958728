import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Switch, Spin, TreeSelect } from "antd";
import { useAppDispatch } from "../../../../../store";
import { ModalProjectAction } from "../../../../../store/Managermodal";
import { addDoc, collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../../../config/firebase.config";
import toast from "react-hot-toast";
import { useForm } from "antd/es/form/Form";
import { increment } from "firebase/database";
interface UpdateBalance {
  id: string;
  balance: number;
}
const UpdateBalance: React.FC<UpdateBalance> = ({ id, balance }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoad, setIsLoad] = React.useState<boolean>(false);
  const [form] = useForm();

  useEffect(() => {
    console.log(balance);
    form.setFieldValue("balance", balance || 0);
  }, [balance, form, id]);

  function formatMoney(number: number) {
    number = Math.round(number * 1000) / 1000;

    return number
      .toFixed(0)
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const onFinish = async (value: any) => {
    const docRef = doc(db, "balance", id);
    const docRef2 = doc(db, "history", id);
    const userRef = doc(db, "users", id);
    setIsLoad(true);
    const docSnap = await getDoc(docRef);
    const userSnap = await getDoc(userRef);

    const transNote = `${value.changeType === "add" ? "Đã cộng" : "Đã trừ"} ${formatMoney(Number(value.balanceChange))} đ vào tài khoản`

    await updateDoc(userRef, {
      balance: (userSnap.data()!.balance || 0) + Number(value.balanceChange) * (
        value.changeType === "add" ? 1 : -1
      )
    });

    if (docSnap.exists()) {
      await addDoc(collection(db, "history"), {
        balance: value.balanceChange,
        note: {
          message: transNote,
          status: "uncheck"
        },
        type: "update",
        uid: id
      })

      await updateDoc(docRef, {
        balance: value.balance,
        note: {
          message: transNote,
          status: "uncheck"
        }
      })
        .then(() => {
          dispatch(ModalProjectAction.CLOSE_MODAL(""));
          window.location.reload();
          setIsLoad(false);
        })
        .catch((error) => {
          setIsLoad(false);
          toast.error("Có lỗi xảy ra, vui lòng thử lại sau");
        });
    }
  };

  const treeData = [
    {
      value: "add",
      title: "Cộng tiền",
    },
    {
      value: "min",
      title: "Trừ tiền",
    },
  ];
  const [valueTree, setValueTree] = useState("add");
  const onChangeTree = (newValue: string) => {
    setValueTree(newValue);
  };

  if (isLoad) return (
    <div className="py-[60px] flex justify-center itemswithdrawal-center">
      <Spin />
    </div>
  )

  return (
    <div>
      <Form name="form_item_path" layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item
          name="balance"
          label=<div className="font-bold mt-[10px]">Số dư</div>
        >
          <Input placeholder="Số dư" disabled />
        </Form.Item>
        <Form.Item
          name="balanceChange"
          label=<div className="font-bold mt-[10px]">Số tiền thay đổi</div>
        >
          <Input placeholder="Số tiền thay đổi" />
        </Form.Item>
        <Form.Item
          name="changeType"
          label=<div className="font-bold">Loại</div>
        >
          <TreeSelect
            style={{ width: "100%" }}
            value={valueTree}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            treeData={treeData}
            treeDefaultExpandAll
            onChange={onChangeTree}
          />
        </Form.Item>
        <div className="flex gap-[15px] justify-end mt-[40px] border-t pt-[10px]">
          <button className="p-[5px]  w-[100px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]"
            onClick={() => dispatch(ModalProjectAction.CLOSE_MODAL(""))}
          >
            {t("Cancle")}
          </button>
          <button className="p-[5px]  w-[100px] min-w-[40px]  bg-[#0cc77f]  text-[#fff] duration-300 hover:text-yellow-300  hover:bg-[#0a965b]">
            {t("Comfirm")}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default UpdateBalance;

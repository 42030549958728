import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Switch,
  TreeSelect,
  UploadProps,
  UploadFile,
  Spin,
  Image,
} from "antd";
import { RcFile } from "antd/es/upload";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
type ViewDrop = {
  data: any;
  image: string;
};
const View: React.FC<ViewDrop> = ({ data, image }) => {
  const [loading, setLoading] = useState(false);

  const [formEdit] = Form.useForm();
  const onFinish = (value: object) => {
    console.log(value);
  };
  // PAGE
  const PageData = [
    {
      value: "1",
      title: "Hoạt đông",
    },
    {
      value: "2",
      title: "Ngưng hoạt động",
    },
  ];

  const [valuePage, setValuePage] = useState<string>("");
  const onChangePage = (newValue: string) => {
    setValuePage(newValue);
  };

  // LOCATION
  const LocationData = [
    {
      value: "0",
      title: "Banner chính",
    },
    {
      value: "1",
      title: "Footer",
    },
    {
      value: "2",
      title: "Thẻ ngân hàng",
    },
    {
      value: "3",
      title: "Trang đăng nhập",
    },
  ];
  const [valueUserLoc, setValueLoc] = useState<string>("");
  const onChangeLoc = (newValue: string) => {
    setValueLoc(newValue);
  };
  useEffect(() => {
    console.log(123);
    
    formEdit.setFieldsValue({
      tieuDe: data.tieuDe,
      thuTu: data.thuTu,
      moTa: data.moTa,
      trang: data.trang,
      viTri: data.viTri,
    });
    setLoading(false);
  }, [data]);
  return (
    <div className="border-t pt-[20px]">
      <Spin spinning={loading} className="border-t pt-[20px]">
        <Form
          form={formEdit}
          name="form_item_path"
          layout="vertical"
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            name="tieuDe"
            label=<div className="font-bold mt-[10px]">Tiêu đề</div>
          >
            <Input placeholder="Tiêu đề" />
          </Form.Item>
          <Form.Item
            name="thuTu"
            label=<div className="font-bold mt-[10px]">Thứ tự</div>
          >
            <Input placeholder="Thứ tự" />
          </Form.Item>
          <Form.Item
            name="moTa"
            label=<div className="font-bold mt-[10px]">Mô tả</div>
          >
            <Input placeholder="Mô tả" />
          </Form.Item>
          <Form.Item
            name="trang"
            label=<div className="font-bold mt-[10px]">Trang</div>
          >
            <TreeSelect
              placeholder="Trang"
              style={{ width: "100%" }}
              value={valuePage}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              treeData={PageData}
              treeDefaultExpandAll
              onChange={onChangePage}
            />
          </Form.Item>
          <Form.Item
            name="viTri"
            label=<div className="font-bold mt-[10px]">Vị trí</div>
          >
            <TreeSelect
              placeholder="Vị trí"
              style={{ width: "100%" }}
              value={valueUserLoc}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              treeData={LocationData}
              treeDefaultExpandAll
              onChange={onChangeLoc}
            />
          </Form.Item>
          <Image width={200} src={`${image}`} />
        </Form>
      </Spin>
    </div>
  );
};

export default View;

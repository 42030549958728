import React, { useState } from "react";
import IMAGES from "../../asets";
import { Alert, Checkbox, Form, Input, TreeSelect } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { UserOutlined, KeyOutlined } from "@ant-design/icons";
import {
  RecaptchaVerifier,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth, db } from "../../config/firebase.config";
import { useAppDispatch } from "../../store";
import { authLogin } from "../../store/managerAuth/thunkAction";
import { doc, getDoc } from "firebase/firestore";

const Login = () => {
  const appDisPath = useAppDispatch();
  const [loading, setLoading] = useState("");
  // DATA
  const treeData = [
    {
      value: "Việt Nam",
      title: "🇻🇳  Việt Nam",
    },
    {
      value: "English",
      title: "🇺🇸 English",
    },
    {
      value: "Trung Quốc",
      title: "🇨🇳 Trung Quốc",
    },
  ];
  const [value, setValue] = useState<string>("🇻🇳 Việt Nam");
  const onChangelanguage = (newValue: string) => {
    setValue(newValue);
  };

  const onFinish = async (value: any) => {
    const user = auth.currentUser;
    try {
      const confirmation = await signInWithEmailAndPassword(
        auth,
        `${value.email}@gmail.com`,
        value.password
      );
      console.log(confirmation.user);

      appDisPath(authLogin(confirmation.user));

      //Check login
      const userRef = doc(db, "admins", confirmation.user.uid);

      let udata: any = await getDoc(userRef);

      window.location.href = "/";
    } catch (error) {
      alert("Email hoặc mật khẩu không đúng!");
    }
    //Add admin
    // createUserWithEmailAndPassword(auth, "admin@gmail.com", "123456")
    //   .then(() => {
    //     console.log("Thanh cong");
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
  };

  const onChangeBox = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
  };

  return (
    <div className="w-full h-screen relative object-cover ">
      <div className="absolute left-0 top-0 right-0 bg-[#f0f2f5] z-10 opacity-90 h-[50%]"></div>
      <div className="absolute left-0 bottom-0 right-0 bg-[#dfebe7] z-10 opacity-90 h-[50%]"></div>
      <div className="absolute z-40 w-full ">
        <div className="w-[70%] m-auto mt-[30px]">
          <TreeSelect
            style={{ width: "250px" }}
            value={value}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            treeData={treeData}
            treeDefaultExpandAll
            onChange={onChangelanguage}
          />
        </div>
      </div>
      <div className="absolute flex justify-center items-center h-full z-20 w-full">
        <div className="bg-white  mt-[0px] w-[550px] top-[50%]  rounded-[20px] left-[40%] border-[2px] py-[30px] px-[24px]">
          <div className="flex flex-col justify-center w-full">
            <img
              className="w-[120px] h-[120px] m-auto"
              src={IMAGES.Logo}
              alt=""
            />
            <Form name="form_item_path" layout="vertical" onFinish={onFinish}>
              <Form.Item
                name="email"
                label=<div className="font-bold mt-[10px]">Tài khoản</div>
              >
                <Input
                  size="large"
                  placeholder="Tên đăng nhập"
                  prefix={<UserOutlined />}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label=<div className="font-bold mt-[10px]">Mật khẩu</div>
              >
                <Input
                  size="large"
                  placeholder="Mật khẩu"
                  type="password"
                  prefix={<KeyOutlined />}
                />
              </Form.Item>
              {/* <Checkbox onChange={onChangeBox}>Ghi nhớ mật khẩu</Checkbox> */}
              <button className="p-[5px] font-bold rounded-[10px] h-[40px] mt-[10px] w-full min-w-[40px]  bg-[#0cc77f]  text-[#fff] duration-300hover:bg-[#0a965b]">
                Đăng nhập
              </button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

import { Form, Input, Button, Switch, Spin, Image } from "antd";
import ImgCrop from "antd-img-crop";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";

import React, { ChangeEvent, useEffect, useState } from "react";
import { Upload } from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { auth, db, storage } from "../../../../config/firebase.config";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import { useForm } from "antd/es/form/Form";
const General: React.FC = () => {
  // IMAGEz
  const [imgSrcLogo, setImgSrcLogo] = useState<any>("");
  const [imgSrcConDau, setImgSrcConDau] = useState<any>("");
  const [imgSrcLogo1, setImgSrcLogo1] = useState<any>("");
  const [imgSrcConDau2, setImgSrcConDau2] = useState<any>("");

  const handelRemoveImageConDau = () => {
    setImgSrcConDau("");
    setImgSrcConDau2("")
  };
  const handelRemoveImageLogo = () => {
    setImgSrcLogo("");
    setImgSrcLogo1("")
  };
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);
  const [formEdit] = Form.useForm();

  useEffect(() => {
    const formGenral = data.map((item) => item.formGenerral);
    const images = data.map((item) => item.image);
    // console.log(images);

    if (formGenral.length > 0) {
      setImgSrcLogo1(images[0].imageLogo);
      setImgSrcConDau2(images[0].imageCondau);
      formEdit.setFieldsValue({
        tenDoanhNghiep: formGenral[0].tenDoanhNghiep,
        tenNguoiDaiDien: formGenral[0].tenNguoiDaiDien,
        chucVu: formGenral[0].chucVu,
        emailDN: formGenral[0].emailDN,
        maSoThue: formGenral[0].maSoThue,
        fullname: formGenral[0].fullname,
        addPress: formGenral[0].addPress,
        mota: formGenral[0].mota,
        chechHienThiFooter: formGenral[0].chechHienThiFooter,
        checkHienThiNhacNho: formGenral[0].checkHienThiNhacNho,
        checkBoxTruTien: formGenral[0].checkBoxTruTien,
        checkBoxChoPhepHoanTien: formGenral[0].checkBoxChoPhepHoanTien,
        checkCTGiaiNgan: formGenral[0].checkCTGiaiNgan,
      });
      setLoading(false);
    }
  }, [data, formEdit]);

  useEffect(() => {
    const collectionRef = collection(db, "desige");
    const unsub = onSnapshot(collectionRef, (snapshot) => {
      let datas: any[] = [];
      snapshot.forEach((doc) => {
        datas.push({ ...doc.data(), id: doc.id });
      });
      setLoading(false)
      setData(datas);
    });
    return unsub;
  }, []);
  // CHANGE DATA

  // XỬ LÝ FILE IAMGE
  const handleChangeFileConDau = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      setImgSrcConDau(file);
    }
  };
  const handleChangeFileLogo = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      setImgSrcLogo(file);
    }
  };

  const handleImageConDau = () => {
    return imgSrcConDau2 ? (
      <Image
        className="my-1"
        style={{ width: "100px", height: "90px" }}
        src={imgSrcConDau2}
        alt="Con Dấu"
      />
    ) : imgSrcConDau ? (
      <Image
        className="my-1"
        style={{ width: "100px", height: "90px" }}
        src={URL.createObjectURL(imgSrcConDau)}
        alt="Con Dấu"
      />
    ) : (
      <p className="border px-[7px] flex rounded-[10px] items-center border-gray-400 w-[100px] h-[90px]">
        Chưa có ảnh
      </p>
    );
  };
  const handleImageLogo = () => {
    return imgSrcLogo1 ? (
      <Image
        className="my-1"
        style={{ width: "100px", height: "90px" }}
        src={imgSrcLogo1}
        alt="Con Dấu"
      />
    ) : imgSrcLogo ? (
      <Image
        className="my-1"
        style={{ width: "100px", height: "90px" }}
        src={URL.createObjectURL(imgSrcLogo)}
        alt="Con Dấu"
      />
    ) : (
      <p className="border px-[7px] flex rounded-[10px] items-center border-gray-400 w-[100px] h-[90px]">
        Chưa có ảnh
      </p>
    );
  };
  const onFinish = async (value: object) => {
    setLoading(true)
    const user = auth.currentUser;
    if (user) {
      console.log(imgSrcLogo.name);
      
      const userDocRef = doc(db, "desige", user.uid);

      const docSnap = await getDoc(userDocRef);

      const storageRef = ref(
        storage,
        `imageDesign/${user.uid}/${imgSrcLogo.name}`
      );
      const storageRef1 = ref(
        storage,
        `imageDesign/${user.uid}/${imgSrcConDau.name}`
      );

      await uploadBytes(storageRef, imgSrcConDau, {});
      const downloadURL = await getDownloadURL(storageRef);

      await uploadBytes(storageRef1, imgSrcLogo, {});
      const downloadURL1 = await getDownloadURL(storageRef1);

      if (!docSnap.exists()) {
        await setDoc(userDocRef, {
          formGenerral: value,
          image: {
            imageLogo: downloadURL1,
            imageCondau: downloadURL,
          },
        });
        setLoading(false)
        console.log("Thêm thành công");
      } else {
        await updateDoc(userDocRef, {
          formGenerral: value,
          image: {
            imageLogo: downloadURL1,
            imageCondau: downloadURL,
          },
        });
        setLoading(false)
        console.log("Cập nhật thành công");
      }
    }
  };
  return (
    <div className="bg-white px-[20px] py-[30px] modal__general">
      <Spin spinning={loading}>
        <Form
          form={formEdit}
          initialValues={{ remember: true }}
          autoComplete="off"
          onFinish={onFinish}
          name="form_item_path"
          layout="vertical"
        >
          <Form.Item
            label=<div className="font-bold">
              Tên đăng ký giấy phép kinh doanh (GPKD) của doanh nghiệp
            </div>
            name="tenDoanhNghiep"
            className="100%"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label=<div className="font-bold">Tên người đại diện</div>
            name="tenNguoiDaiDien"
            className="100%"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label=<div className="font-bold">Chức vụ người đại diện</div>
            name="chucVu"
            className="100%"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label=<div className="font-bold">Email doanh nghiệp</div>
            name="emailDN"
            className="100%"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label=<div className="font-bold">Thông tin mã số thuế</div>
            name="maSoThue"
            className="100%"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label=<div className="font-bold">SĐT</div>
            name="fullname"
            className="100%"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label=<div className="font-bold">Địa chỉ</div>
            name="addPress"
            className="100%"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label=<div className="font-bold">Mô tả</div>
            name="mota"
            className="100%"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label=<div className="font-bold">Hiện thị footer</div>
            name="chechHienThiFooter"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label=<div className="font-bold">Hiện thị thông báo nhắc nhở</div>
            valuePropName="checked"
            name="checkHienThiNhacNho"
            className="100%"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label=<div className="font-bold">
              Trừ tiền ngay sau khi bấm rút tiền
            </div>
            valuePropName="checked"
            name="checkBoxTruTien"
            className="100%"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label=<div className="font-bold">
              Cho phép hoàn tiền khi từ chối rút tiền
            </div>
            valuePropName="checked"
            name="checkBoxChoPhepHoanTien"
            className="100%"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label=<div className="font-bold">Hiện thị chi tiết giải ngân</div>
            valuePropName="checked"
            name="checkCTGiaiNgan"
            className="100%"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label=<div className="font-bold">Logo</div>
            valuePropName="checked"
            className="100%"
          >
            <div className="flex items-center gap-5 ">
              {handleImageLogo()}
              <label
                htmlFor="file-input"
                className="bg-[#DCEDFF] font-bold border p-1 rounded-lg  border-[#1386ED] text-[#1386ED]  cursor-pointer"
              >
                Chọn
              </label>
              <p
                onClick={() => {
                  handelRemoveImageLogo();
                }}
                className="bg-[#DCEDFF] font-bold border-[#1386ED] border p-1 rounded-lg text-[#1386ED] cursor-pointer"
              >
                Xóa
              </p>
              <span>Kích thước tối đa 5MB</span>
            </div>
            <input
              type="file"
              id="file-input"
              style={{ display: "none" }}
              onChange={handleChangeFileLogo}
            />
          </Form.Item>
          <Form.Item
            label=<div className="font-bold">Con Dấu</div>
            className="100%"
          >
            <div className="flex items-center gap-5 ">
              {handleImageConDau()}
              <label
                htmlFor="file-input-condau"
                className="bg-[#DCEDFF] font-bold border p-1 rounded-lg  border-[#1386ED] text-[#1386ED]  cursor-pointer"
              >
                Chọn
              </label>
              <p
                onClick={() => {
                  handelRemoveImageConDau();
                }}
                className="bg-[#DCEDFF] font-bold border-[#1386ED] border p-1 rounded-lg text-[#1386ED] cursor-pointer"
              >
                Xóa
              </p>
              <span>Kích thước tối đa 5MB</span>
            </div>
            <input
              type="file"
              id="file-input-condau"
              style={{ display: "none" }}
              onChange={handleChangeFileConDau}
            />
          </Form.Item>
          <button className="p-[5px]  w-[80px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]">
            Lưu
          </button>
        </Form>
      </Spin>
    </div>
  );
};

export default General;

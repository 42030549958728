import { Empty, Spin } from 'antd';
import { collection, getDocs } from 'firebase/firestore';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { db } from '../../../../../config/firebase.config';
import { useAppDispatch } from '../../../../../store';
import { ModalProjectAction } from '../../../../../store/Managermodal';
import ApproveTheContract from '../ApproveTheContract';
import RefuseTheContract from '../RefuseTheContract';
import EditContract from '../EditContract';
import WatchContract from '../WatchContract';

export default function ContractInformation({ uid, contract }: any) {
    const AppDispath = useAppDispatch();
    const [isInfomaion, setIsInfomaion] = useState<any | null>();
    const [loading, setLoading] = useState(false);

    const getDetailContact = async () => {
        setLoading(true);
        const contractRef = collection(db, "loan-list");
        const querySnapshot = await getDocs(contractRef);

        const contract: any[] = [];

        querySnapshot.forEach((doc) => {
            contract.push(doc.data());
        });

        contract.forEach((item) => {
            if (item.uid === uid) {
                setIsInfomaion(item);
            }
        });
        setLoading(false);
    }

    useEffect(() => {
        getDetailContact();
    }, []);


    function formatMoney(number: number) {
        number = Math.round(number * 1000) / 1000;

        return number
            .toFixed(0)
            .replace(/\D/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    return (
        <div className="bg-white w-full p-[10px] rounded-[10px]">
            <h1 className='text-lg font-bold'>{t("ContractInformation")}</h1>
            {
                isInfomaion ? (
                    <div className='mt-4 flex-1 flex w-full justify-between items-center'>
                        <div className=' flex flex-col gap-2'>
                            <div className='flex items-center gap-2'>
                                <span className='font-bold'>Số tiền:</span>
                                <span>{formatMoney(isInfomaion.loan.loanAmount)}VND</span>
                            </div>
                            <div className='flex items-center gap-2'>
                                <span className='font-bold'>Kì hạn:</span>
                                <span>{isInfomaion.loan.loanMonth}</span>
                            </div>
                            <div className='flex items-center gap-2'>
                                <span className='font-bold'>Ngày giao dịch :</span>
                                <span>{isInfomaion.BorrowedTime}</span>
                            </div>
                            <div className='flex items-center gap-2'>
                                <span className='font-bold'>Trạng thái :</span>
                                <span className={`py-1 text-white font-semibold px-3 rounded-lg ${isInfomaion.status === 'pending' ? 'bg-yellow-400' : isInfomaion.status === 'approved' ? 'bg-green-400' : 'bg-red-400'}`} >
                                    {isInfomaion.status === 'pending' ? 'Đang chờ duyệt' : isInfomaion.status === 'approved' ? 'Đã duyệt' : 'Đã hủy'}
                                </span>
                            </div>
                            <div className='flex items-center gap-2'>
                                <span className='font-bold'>Tiêu đề duyệt : {isInfomaion.tieuDeDuyet}</span>
                                <span></span>
                            </div>
                            <div className='flex items-center gap-2'>
                                <span className='font-bold'>Nội dung duyệt: {isInfomaion.noiDungDuyet}</span>
                                <span></span>
                            </div>
                        </div>
                        <div className='flex-1 flex items-end gap-2 justify-center flex-col'>
                            <button
                                onClick={() => {
                                    AppDispath(
                                        ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                                            content: <ApproveTheContract uid={uid} edit={isInfomaion.status !== "pending"} />,
                                            title: <>{isInfomaion.status === "pending" ? "Duyệt hợp đồng" : "Chỉnh sửa nội dung duyệt"} </>,
                                            width: "500px",
                                        })
                                    );
                                }}
                                className="py-[0.3rem] w-full max-w-[20rem] duration-150 hover:bg-white border border-transparent hover:border-blue-500 hover:text-blue-500 rounded-lg bg-blue-500 text-white"
                            >
                                {isInfomaion.status === "pending" ? "Duyệt hợp đồng" : "Chỉnh sửa nội dung duyệt"}
                            </button>
                            {
                                isInfomaion.status === "pending" &&
                                <button
                                    onClick={() => {
                                        AppDispath(
                                            ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                                                content: <RefuseTheContract uid={uid} />,
                                                title: <>Cập nhật người dùng</>,
                                                width: "500px",
                                            })
                                        );
                                    }}
                                    className="py-[0.3rem] w-full max-w-[20rem] duration-150 hover:bg-white border border-transparent hover:border-red-500 hover:text-red-500 rounded-lg bg-red-500 text-white"
                                >
                                    Từ chối
                                </button>
                            }
                            <button
                                onClick={() => {
                                    AppDispath(
                                        ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                                            content: <EditContract uid={uid} />,
                                            title: <>Cập nhật người dùng</>,
                                            width: "500px",
                                        })
                                    );
                                }}
                                className="py-[0.3rem] w-full max-w-[20rem] duration-150 hover:bg-white border border-transparent hover:border-gray-500 hover:text-gray-500 rounded-lg bg-gray-500 text-white"
                            >
                                Chỉnh sửa hợp đồng
                            </button>
                            <button
                                onClick={() => {
                                    AppDispath(
                                        ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                                            content: <WatchContract contract={contract} uid={uid} />,
                                            title: <>Cập nhật người dùng</>,
                                            width: "500px",
                                        })
                                    );
                                }}
                                className="py-[0.3rem] w-full max-w-[20rem] duration-150 hover:bg-white border border-transparent hover:border-purple-500 hover:text-purple-500 rounded-lg bg-purple-500 text-white"
                            >
                                Xem hợp đồng
                            </button>
                        </div>
                    </div>
                ) : (
                    // <Empty description={t("NoDataContac")} />
                    <div className='w-full flex justify-center items-center h-[20vh]'>
                        {
                            loading ? <Spin /> : <Empty description={t("NoData")} />
                        }


                    </div>
                )
            }
        </div>
    )
}

import React, { useEffect, useState } from "react";
import { Form, Input, Button, Switch, TreeSelect, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { collection, doc, getDocs, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../../../config/firebase.config";
import { useForm } from "antd/es/form/Form";
interface ChangeSales {
  id: string;
  uid: string;
}
const ChangeSales: React.FC<ChangeSales> = ({ id, uid }) => {
  const { t, i18n } = useTranslation();
  const [treeData, setTreeData] = useState([]);
  const [form] = useForm();
  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {
    const collectionRef = collection(db, "admins");

    onSnapshot(collectionRef, (snapshot) => {
      let users: any = [];
      snapshot.forEach((doc) => {
        users.push({ 
          value: doc.id,
          phoneNumber: doc.data().phoneNumber,
          title: doc.data().fullName,
         });
      });
      setTreeData(users);
    });
  }, []);

  const onFinish = async (value: any) => {
    setLoading(true);
    const sellerData: any = treeData.find((i: any) => i.value === value.seller);
    const docRef = doc(db, "users", uid);

    console.log(sellerData);

    await setDoc(docRef, {
      seller: value.seller,
      sellerPhone: sellerData.phoneNumber,
      sellerName: sellerData.title
    }, {
      merge: true
    });

    const contractRef = collection(db, "loan-list");
        const querySnapshot = await getDocs(contractRef);

        const contract: any[] = [];

        querySnapshot.forEach((doc) => {
            contract.push(doc.data());
        });

        const citiesRef = collection(db, "loan-list");
        const q = await getDocs(citiesRef);
        q.forEach(async (doc) => {
            if (doc.data().uid === uid) {
              await setDoc(doc.ref, {
                seller: value.seller,
                sellerPhone: sellerData.phoneNumber,
                sellerName: sellerData.title
              }, {
                merge: true
              });
    
              window.location.reload();
            }
        });
  };

  // TREE
  const [valueTree, setValueTree] = useState("");
  const onChangeTree = (newValue: string) => {
    setValueTree(newValue);
  };

  if (loading) return (
    <div className="py-[60px] flex justify-center items-center">
      <Spin />
    </div>
  )
  
  return (
    <div className="border-t pt-[20px]">
      <Form name="form_item_path" layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            name="seller"
            label=<div className="font-bold">Người bán</div>
          >
            <TreeSelect
              style={{ width: "100%" }}
              value={valueTree}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              treeData={treeData}
              treeDefaultExpandAll
              onChange={onChangeTree}
            />
          </Form.Item>

        <div className="flex gap-[15px] justify-end mt-[40px] border-t pt-[10px]">
          <button className="p-[5px]  w-[80px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]">
            {t("Cancle")}
          </button>
          <button className="p-[5px]  w-[80px] min-w-[40px]  bg-[#0cc77f]  text-[#fff] duration-300 hover:text-yellow-300  hover:bg-[#0a965b]">
           Xác nhận
          </button>
        </div>
      </Form>
    </div>
  );
};

export default ChangeSales;

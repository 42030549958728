import { Collapse, DatePicker, Form, Input, Select } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/vi";
dayjs.locale("vi");

const SearchLoans = ({ onSearch }: any) => {
  const { t, i18n } = useTranslation();

  const onFinish = (value: any) => {
    onSearch({
      value,
    });
  };
  const { Option } = Select;
  const formatDate = (dateString: string): Dayjs | null => {
    return dateString ? dayjs(dateString) : null;
  };
  // Assuming you have a list of status options
  const statusOptions = [
    {
      value: "approved",
      title: "Đã duyệt",
    },
    {
      value: "pending",
      title: "Đang chờ",
    },
    {
      value: "reject",
      title: "Từ chối",
    },
  ];
  const statusTime = [
    {
      value: "reject",
      title: "Thời gian",
    },
    {
      value: "approved",
      title: "Hôm nay",
    },
    {
      value: "pending",
      title: "Tuần này",
    },
    {
      value: "reject",
      title: "Tháng này",
    },
  ];

  const searchItem = () => {
    return (
      <Form
        className="mt-[20px] mb-[20px]"
        name="form_item_path"
        layout="vertical"
        onFinish={onFinish}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[15px]">
          <Form.Item
            name="maDon"
            label=<div className="font-bold">{t("Mã đơn")}</div>
          >
            <Input placeholder={t("Mã đơn")} />
          </Form.Item>
          <Form.Item
            name="name"
            label=<div className="font-bold">{t("Người dùng")}</div>
          >
            <Input placeholder={t("Người dùng")} />
          </Form.Item>
          <Form.Item
            name="status"
            label={<div className="font-bold">{t("Status")}</div>}
          >
            <Select placeholder={t("Select status")}>
              {statusOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[15px]">
          <Form.Item
            name="tuNgay"
            label={<div className="font-bold">{t("Từ ngày")}</div>}
          >
            <DatePicker className="w-full" placeholder={t("Từ ngày")} />
          </Form.Item>

          <Form.Item
            name="denNgay"
            label={<div className="font-bold">{t("Đến ngày")}</div>}
          >
            <DatePicker className="w-full" placeholder={t("Đến ngày")} />
          </Form.Item>

          <Form.Item
            name="thoiGian"
            label={<div className="font-bold">{t("Thời gian")}</div>}
          >
            <Select placeholder={t("Thời gian")}>
              {statusTime.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Form.Item label=<div className="font-bold">{t("Action")}</div>>
          <button className="p-[5px]  w-[80px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]">
            {t("Search")}
          </button>
        </Form.Item>
      </Form>
    );
  };
  return (
    <Collapse
      expandIconPosition="end"
      className="mt-[30px] mb-[20px] bg-[#fafafa]"
      items={[
        {
          key: "1",
          label: <>{t("FilterSearch")} (0)</>,
          children: searchItem(),
        },
      ]}
    />
  );
};

export default SearchLoans;

import { Dropdown, Image, MenuProps, Space, Spin, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { useAppDispatch } from "../../../../store";
import { ModalProjectAction } from "../../../../store/Managermodal";
import Add from "./Components/Modal/Add";
import Views from "./Components/Modal/Views";
import Update from "./Components/Modal/Update";
import { collection, deleteDoc, doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../../config/firebase.config";
const Banner: React.FC = () => {
  const [dataBanner, setDataBanner] = useState<any[]>([]);
  useEffect(() => {
    const collectionRef = collection(db, "banner");
    const unsub = onSnapshot(collectionRef, (snapshot) => {
      let datas: any[] = [];
      snapshot.forEach((doc) => {
        datas.push({ ...doc.data(), id: doc?.id });
      });
      setDataBanner(datas);
    });
    return unsub;
  }, []);
  const AppDispath = useAppDispatch();
  // COLUMNS
  interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
    tags: string[];
  }

  const columns: ColumnsType<any> = [
    {
      title: "Hình ảnh",
      key: "image",
      render: (text, record) => (
        <div className="w-[120px] h-[120px]">
          <Image src={record.imagesBaner?.banner} alt="Hình ảnh" />
        </div>
      ),
    },
    {
      title: "Trang",
      key: "thuTu",
      render: (text, record) => (
        <div className="w-[120px] h-[120px]">
          {record.banners?.trang === "1" ? "Đang hoạt đông" : "Ngưng hoạt động"}
        </div>
      ),
    },
    {
      title: "Vị trí",
      key: "viTri",
      render: (text, record) => {
        let content;

        if (record.banners?.viTri === "0") {
          content = <span>Banner chính</span>;
        } else if (record.banners?.viTri === "1") {
          content = <span>Footer</span>;
        } else if (record.banners?.viTri === "2") {
          content = <span>Thẻ ngân hàng</span>;
        } else {
          content = <span>Trang đăng nhập</span>;
        }

        return <div className="w-[120px] h-[120px]">{content}</div>;
      },
    },
    {
      title: "Thứ tự",
      dataIndex: "thuTu",
      key: "thuTu",
    },
    {
      title: "",
      key: "action",
      render: (_, record) => {
        //ITEM
        const items: MenuProps["items"] = [
          {
            label: (
              <p
                onClick={() => {
                  AppDispath(
                    ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                      content: (
                        <Views
                          data={record?.banners}
                          image={record.imagesBaner?.banner}
                        />
                      ),
                      title: "Xem banner",
                      width: "500px",
                    })
                  );
                }}
              >
                Xem
              </p>
            ),
            key: "0",
          },
          {
            label: (
              <p
                onClick={() => {
                  AppDispath(
                    ModalProjectAction.OPEN_EDIT_FORM_MODAL({
                      content: (
                        <Update
                          data={record?.banners}
                          image={record.imagesBaner?.banner}
                          id={record?.id}
                        />
                      ),
                      title: "Cập nhật banner",
                      width: "500px",
                    })
                  );
                }}
              >
                Cập nhập
              </p>
            ),
            key: "1",
          },
          {
            label: (
              <p
                onClick={ async () => {
                  await deleteDoc(doc(db, "banner", record?.id));
                }}
              >
                Xóa
              </p>
            ),
            key: "3",
          },
        ];

        return (
          <Dropdown menu={{ items }} trigger={["click"]}>
            <a
              className="font-bold text-[12px]"
              onClick={(e) => e.preventDefault()}
            >
              <Space>
                Thao tác
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        );
      },
    },
  ];

  const data: DataType[] = dataBanner;

  return (
    <div className="bg-white px-[20px] py-[30px]">
      <button
        onClick={() => {
          AppDispath(
            ModalProjectAction.OPEN_EDIT_FORM_MODAL({
              content: <Add />,
              title: "Quản lí banner",
              width: "500px",
            })
          );
        }}
        className="p-[5px] mb-[20px]  w-[80px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]"
      >
        Thêm mới
      </button>
      <Table
        columns={columns}
        dataSource={data}
        loading={data.length === 0 ? true : false}
      />
    </div>
  );
};

export default Banner;

import { doc, updateDoc } from "@firebase/firestore";
import { Form, Input, TreeSelect } from "antd";
import { useForm } from "rc-field-form";
import React, { useState } from "react";
import { db } from "../../../../../config/firebase.config";
import { useAppDispatch } from "../../../../../store";
import { ModalProjectAction } from "../../../../../store/Managermodal";
type UpdateDrop = {
  id: string;
  editData: any;
};
const Update: React.FC<UpdateDrop> = ({ id, editData }) => {
  const AppDispath = useAppDispatch();
  const [form] = useForm();
  const [nameAccount, setNameAccount] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [fullName, setFullName] = useState('');

  const onFinish = (value: object) => {
    const data = {
      nameAccount,
      phoneNumber,
      fullName,
      status: valueTree,
      accountType: valueUser
    }

    const docRef = doc(db, 'users', id);

    updateDoc(docRef, data);

    AppDispath(
      ModalProjectAction.CLOSE_MODAL('')
    )
  };
  // DATA TREE
  const treeData = [
    {
      value: "active",
      title: "Hoạt đông",
    },
    {
      value: "block",
      title: "Ngưng hoạt động",
    },
  ];
  // TREE
  const [valueTree, setValueTree] = useState<string>(editData.status ?? "active");
  const onChangeTree = (newValue: string) => {
    setValueTree(newValue);
  };
  // DATA TREE
  const UserData = [
    {
      value: "user",
      title: "Người dùng",
    },
    {
      value: "hub",
      title: "Cộng tác viên",
    },
  ];
  // TREE
  const [valueUser, setValueUser] = useState<string>(editData.accountType ?? "user");
  const onChangeUser = (newValue: string) => {
    setValueUser(newValue);
  };
  return (
    <div className="border-t pt-[20px]">
      <Form name="form_item_path" layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="nameAccount"
          label={<div className="font-bold mt-[10px]">Tên đăng nhập</div>}
        >
          <Input placeholder="Tên đăng nhập" defaultValue={editData.nameAccount} onChange={e => setNameAccount(e.currentTarget.value)} />
        </Form.Item>
        <Form.Item label=<div className="font-bold mt-[10px]">SĐT</div>>
          <Input placeholder="SĐT" defaultValue={editData.phoneNumber} onChange={e => setPhoneNumber(e.currentTarget.value)} />
        </Form.Item>
        <Form.Item label=<div className="font-bold mt-[10px]">Tên</div>>
          <Input placeholder="Tên" defaultValue={editData.fullName} onChange={e => setFullName(e.currentTarget.value)} />
        </Form.Item>
        <Form.Item label=<div className="font-bold mt-[10px]">Trạng thái</div>>
          <TreeSelect
            style={{ width: "100%" }}
            value={valueTree}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            treeData={treeData}
            treeDefaultExpandAll
            onChange={onChangeTree}
          />
        </Form.Item>
        <Form.Item label=<div className="font-bold mt-[10px]">Vai trò</div>>
          <TreeSelect
            style={{ width: "100%" }}
            value={valueUser}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            treeData={UserData}
            treeDefaultExpandAll
            onChange={onChangeUser}
          />
        </Form.Item>

        <div className="flex gap-[15px] justify-end mt-[40px] border-t pt-[10px]">
          <button className="p-[5px]  w-[80px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]" onClick={() => {
            AppDispath(
              ModalProjectAction.CLOSE_MODAL('')
            )
          }}>
            Hủy
          </button>
          <button className="p-[5px]  w-[80px] min-w-[40px]  bg-[#0cc77f]  text-[#fff] duration-300 hover:text-yellow-300  hover:bg-[#0a965b]">
            Xác nhận
          </button>
        </div>
      </Form>
    </div>
  );
};

export default Update;

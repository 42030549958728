import { Collapse } from "antd";
import React, { useEffect, useState } from "react";
import TableOverview from "./Componenst/TableOverview";
import SearchOverviews from "./Componenst/Collapse";
import { useTranslation } from "react-i18next";
import { auth, db } from "../../config/firebase.config";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
// import { list } from "firebase"
function removeTones(str: string | undefined | null) {
  if (!str) {
    return '';
  }

  // remove accents
  var from =
      "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ",
    to =
      "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(RegExp(from[i], "gi"), to[i]);
  }

  str = str.toLowerCase().trim();
  return str;
}
const Overview = () => {
  const [listUser, setListUser] = useState<any[]>([]);
  const [filter, setFilter] = useState({
    phone: "",
    name: "",
    xacThuc: "",
    trangThai: "",
  });
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const dateNow = new Date();
  const day = dateNow.getDate();
  const month = dateNow.getMonth() + 1;
  const year = dateNow.getFullYear();
  const date = `${day}/${month}/${year}`;
  const [user, setUser] = useState<any>();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(db.app), (userAuth) => {
      // Check if a user is signed in or signed out
      if (userAuth) {
        setUser(userAuth);
        getUser();
      }
    });

    // Clean up the subscription to avoid memory leaks
    return () => unsubscribe();
  }, [user]);

  const onSearch = async (searchData: any) => {
    setLoading(true);
    setFilter(searchData.value);
  };
  const getUser = async () => {
    if (!user) return;
    // Get all user
    const userRef = collection(db, "users");
    const querySnapshot = await getDocs(userRef);
    const users: any[] = [];
    querySnapshot.forEach((doc) => {
      if (user?.email !== "admin@gmail.com" && doc.data().seller !== user?.uid)
        return;
      users.push(doc.data());
    });
    setListUser(users);
    setLoading(false);
  };
  useEffect(() => {
    const collectionRef = collection(db, "users");
    const unsub = onSnapshot(collectionRef, (snapshot) => {
      let users: any[] = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        if (!data) return;
        if (filter?.name) {
          const filterName = removeTones(filter?.name);
          const fullName = removeTones(data.fullName);
          if (!fullName.includes(filterName)) return;
        }
        if (filter?.phone) {
          const filterDon = removeTones(filter?.phone);
          const fullPhone = removeTones(data.myPhoneNumber);
          if (!fullPhone.includes(filterDon)) return;
        }
        if (filter?.xacThuc) {
          const filterXacThuc = removeTones(filter?.xacThuc);
          const fullCmnd = removeTones(data.cmnd);
          if (!fullCmnd.includes(filterXacThuc)) return;
        }
        if (filter?.trangThai) {
          const filterName = removeTones(filter?.trangThai);
          const fullStatus = removeTones(data.status);
          if (!fullStatus.includes(filterName)) return;
        }

        users.push({ ...doc.data(), id: doc.id });
      });
      console.log(users);
      
      setListUser(users);
      setLoading(false);
    });
    return unsub;
  }, [filter, user]);
  return (
    <div className="ml-[30px]">
      <h1 className="text-[22px] font-bold mb-[15px] text-[#4e4e4e]">
        {t("titleOverview")}
      </h1>
      <div className="grid grid-cols-3 gap-[15px] my-[15px]">
        <div className="bg-white py-[10px] px-[15px]">
          <h1 className="text-[22px] mb-[10px] font-bold text-[#4e4e4e]">
            {listUser.length}
          </h1>
          <p className="text-[13px] mb-[5px]">{t("OverviewTotalUser")}</p>
        </div>
        <div className="bg-white py-[10px] px-[15px]">
          <h1 className="text-[22px] mb-[10px] font-bold text-[#4e4e4e]">
            {listUser.filter((user) => user.accountCreated === date).length}
          </h1>
          <p className="text-[13px] mb-[5px]">{t("OverviewTotalRegister")}</p>
        </div>
        <div className="bg-white py-[10px] px-[15px]">
          <h1 className="text-[22px] mb-[10px] font-bold text-[#4e4e4e]">
            {listUser.filter((user) => user.status === "verified").length}
          </h1>
          <p className="text-[13px] mb-[5px]">{t("OverviewTotalVerify")}</p>
        </div>
      </div>
      <SearchOverviews onSearch={onSearch} />
      <TableOverview listUser={listUser} loading={loading} />
    </div>
  );
};

export default Overview;

import { Button, Popconfirm, Spin } from "antd";
import React, { useState } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../../store";
import { ModalProjectAction } from "../../../../../store/Managermodal";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../../../../config/firebase.config";
type DeleteDrop = {
  id: string;
};
const Delete: React.FC<DeleteDrop> = ({ id }) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const deleteAccount = async () => {
    setLoading(true);
    const userRef = doc(db, "users", id);

    await deleteDoc(userRef);

    window.location.reload();
  }

  if (loading) return (
    <div className="py-[60px] flex justify-center items-center">
      <Spin />
    </div>
  )

  return (
    <div>
      <div className="py-[30px] text-center">
        <p className="flex items-center gap-[20px] justify-center">
          <svg
            className="text-yellow-500 text-[26px]"
            viewBox="64 64 896 896"
            focusable="false"
            data-icon="exclamation-circle"
            width="1em"
            height="1em"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
            <path d="M464 688a48 48 0 1096 0 48 48 0 10-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"></path>
          </svg>{" "}
          <span className="font-bold text-[16px]">{t("DeleteUser")}</span>
        </p>
        <p>{t("question")}</p>
      </div>
      <div className="flex gap-[15px] justify-end mt-[40px]">
        <button className="p-[5px]  w-[80px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]"
          onClick={() => dispatch(ModalProjectAction.CLOSE_MODAL(''))}
        >
          {t("Cancle")}
        </button>
        <button onClick={deleteAccount} className="p-[5px] w-[80px] min-w-[40px]  bg-[#1890ff] border text-[#fff] duration-300 hover:text-[#078ddb] hover:bg-[#fff] hover:border-[#078ddb]">
          {t("Comfirm")}
        </button>
      </div>
    </div >
  );
};

export default Delete;

import { Divider, Tag } from "antd";
import React from "react";
import { auth, db } from "../../../../config/firebase.config";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";

type ViewDrop = {
  data: any;
};
const Views: React.FC<ViewDrop> = ({ data }) => {
  // Format tiền
  function formatMoney(number: number) {
    number = Math.round(number * 1000) / 1000;
    return number
      .toFixed(0)
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  function formatPhone(phone: any) {
    return phone?.replace(/^\+84/, "0");
  }
  // Update status
  const handleUpdateStatusReject = async () => {
    const docRef = doc(db, 'loan-list', data.id);

    updateDoc(docRef, {
      status: "reject"
    });
  };

  const handleUpdateStatusApprove = () => {
    const docRef = doc(db, 'loan-list', data.id);
    const userRef = doc(db, 'users', data.loan.uid);

    updateDoc(docRef, {
      status: "approved"
    });

    updateDoc(userRef, {
      balance: data.loan?.loanAmount ?? 0
    });
  };

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2">
        <div className="">
          <Divider orientation="left">
            <span className="font-[400] text-[14px]">Thông tin khoản vay</span>
          </Divider>
          <div className="flex py-[9px]">
            <span className="font-bold w-[50%]">Mã giao dịch</span>
            <span className="w-[50%]">{data.idContract}</span>
          </div>
          <div className="flex py-[9px]">
            <span className="font-bold w-[50%]">Ngày giao dịch</span>
            <span className="w-[50%]">{data.BorrowedTime}</span>
          </div>
          <div className="flex py-[9px]">
            <span className="font-bold w-[50%]">Người dùng</span>
            <span className="w-[50%]">
              {formatPhone(data.myPhoneNumber)} ({data.fullName})
            </span>
          </div>
          <div className="flex py-[9px]">
            <span className="font-bold w-[50%]">Khoản vay</span>
            <span className="w-[50%]">
              {formatMoney(data.loan.loanAmount)}đ
            </span>
          </div>
          <div className="flex py-[9px]">
            <span className="font-bold w-[50%]">Kỳ hạn</span>
            <span className="w-[50%]">{data.loan.loanMonth} Tháng</span>
          </div>
          <div className="flex py-[9px]">
            <span className="font-bold w-[50%]">Trạng thái</span>
            <span className="w-[50%]">
              {data.status === "pending" ? (
                <Tag color="yellow">Đang chờ</Tag>
              ) : data.status === "approved" ? (
                <Tag color="green">Đã duyệt</Tag>
              ) : (
                <Tag color="red">Từ chối</Tag>
              )}
            </span>
          </div>
          <div className="flex py-[9px]">
            <span className="font-bold w-[50%]">Nội dung</span>
            <span className="w-[50%]">
              Thực hiện khoản vay {formatMoney(data.loan.loanAmount)}đ trong{" "}
              {data.loan.loanMonth} tháng.
            </span>
          </div>
        </div>
        <div className="">
          <Divider orientation="left">
            <span className="font-[400] text-[14px]">Thông tin</span>
          </Divider>
          <div className="flex py-[9px]">
            <span className="font-bold w-[50%]">Tên:</span>
            <span className="w-[50%]">{data.fullName}</span>
          </div>
          <div className="flex py-[9px]">
            <span className="font-bold w-[50%]">Adress:</span>
            <span className="w-[50%]">{data.address}</span>
          </div>
          <div className="flex py-[9px]">
            <span className="font-bold w-[50%]">CCCD/CMND:</span>
            <span className="w-[50%]">{data.cmnd}</span>
          </div>
          <div className="flex py-[9px]">
            <span className="font-bold w-[50%]">Giới tính:</span>
            <span className="w-[50%]">{data.genre}</span>
          </div>
          <div className="flex py-[9px]">
            <span className="font-bold w-[50%]">Ngày sinh: </span>
            <span className="w-[50%]">{data.dateOfBirth}</span>
          </div>
          <div className="flex py-[9px]">
            <span className="font-bold w-[50%]">Nghề nghiệp:</span>
            <span className="w-[50%]">{data.job}</span>
          </div>
          <div className="flex py-[9px]">
            <span className="font-bold w-[50%]">Mức lương:</span>
            <span className="w-[50%]">
              {data.income === "1" ? (
                <p>Lương dưới 5 triệu</p>
              ) : data.income === "2" ? (
                <p>Lương từ 5tr đến 10tr</p>
              ) : data.income === "3" ? (
                <p>Lương từ 10tr đến 20tr</p>
              ) : (
                <p>Lường 20tr</p>
              )}
            </span>
          </div>
          <div className="flex py-[9px]">
            <span className="font-bold w-[50%]">Mục đích vay:</span>
            <span className="w-[50%]">{data.purpose}</span>
          </div>
          <div className="flex py-[9px]">
            <span className="font-bold w-[50%]">SĐT người thân:</span>
            <span className="w-[50%]">{data.phoneNumber}</span>
          </div>
          <div className="flex py-[9px]">
            <span className="font-bold w-[50%]">Quan hệ người thân:</span>
            <span className="w-[50%]">{data.relationship}</span>
          </div>
          <Divider orientation="left">
            <span className="font-[400] text-[14px]">Ngân hàng</span>
          </Divider>
          <div className="flex py-[9px]">
            <span className="font-bold w-[50%]">Tên ngân hàng:</span>
            <span className="w-[50%]">{data.nameBank}</span>
          </div>
          <div className="flex py-[9px]">
            <span className="font-bold w-[50%]">Số tài khoản:</span>
            <span className="w-[50%]">{data.numberBank}</span>
          </div>
          <div className="flex py-[9px]">
            <span className="font-bold w-[50%]">Tên chủ tài khoản:</span>
            <span className="w-[50%]">{data.nameAccount}</span>
          </div>
        </div>
      </div>
      {!(data.status === "approved" || data.status === "rejected") && (
        <div className="flex gap-[15px] justify-end mt-[40px] ">
          <button
            className="p-[5px]  w-[80px] min-w-[40px] bg-red-500 text-[#fff] duration-300 hover:bg-[#ce290c]"
            onClick={handleUpdateStatusReject}
          >
            Từ chối
          </button>
          <button
            className="p-[5px]  w-[80px] min-w-[40px]  bg-[#0cc77f]  text-[#fff] duration-300 hover:text-yellow-300  hover:bg-[#0a965b]"
            onClick={handleUpdateStatusApprove}
          >
            Duyệt
          </button>
        </div>
      )}
    </div>
  );
};

export default Views;

import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Switch,
  TreeSelect,
  UploadProps,
  UploadFile,
  Spin,
  Image,
} from "antd";
import { RcFile } from "antd/es/upload";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { auth, db, storage } from "../../../../../../../config/firebase.config";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import { useAppDispatch } from "../../../../../../../store";
import { ModalProjectAction } from "../../../../../../../store/Managermodal";
const Add = () => {
  const [soViTriDaCoo, setSoViTriDaCoo] = useState<any>("");
  const [imageBanner, setImageBanner] = useState<any>("");
  const [imageBanner1, setImageBanner1] = useState<any>("");
  const AppDistPath = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [formEdit] = Form.useForm();
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const images = data.map((item) => item.image);
    if (images.length > 0) {
      setImageBanner1(images[0]?.imageBanner);
      setLoading(false);
    }
    const loCation = data.map((item) => item.banners?.viTri);
    setSoViTriDaCoo(loCation);
  }, [data, formEdit]);
  useEffect(() => {
    const collectionRef = collection(db, "banner");
    const unsub = onSnapshot(collectionRef, (snapshot) => {
      let datas: any[] = [];
      snapshot.forEach((doc) => {
        datas.push({ ...doc.data(), id: doc.id });
      });
      setData(datas);
    });
    return unsub;
  }, []);
  // PAGE
  const PageData = [
    {
      value: "1",
      title: "Hoạt đông",
    },
    {
      value: "2",
      title: "Ngưng hoạt động",
    },
  ];

  const [valuePage, setValuePage] = useState<string>("");
  const onChangePage = (newValue: string) => {
    setValuePage(newValue);
  };
  // ONFINISH
  const onFinish = async (value: object) => {
    if (data.length <= 4) {
      setLoading(true);
      const user = auth.currentUser;
      if (user) {
        const userCollectionRef = collection(db, "banner");
        const userDocRef = doc(userCollectionRef, user.uid);
        const docSnap = await getDoc(userDocRef);
        const storageRef = ref(
          storage,
          `imageDesign/${user.uid}/${imageBanner.name}`
        );
        await uploadBytes(storageRef, imageBanner, {});
        const downloadURL = await getDownloadURL(storageRef);
        if (!docSnap.exists()) {
          await addDoc(userCollectionRef, {
            banners: value,
            imagesBaner: {
              banner: downloadURL,
            },
          });
          await formEdit.resetFields();
          await AppDistPath(ModalProjectAction.CLOSE_MODAL(""));
          await setLoading(false);
          await setImageBanner("");
        }
      }
    } else {
      alert("Data đã giới hạn 4 cột (vui long xóa bang để cũ để thêm) ");
    }
  };
  // LOCATION
  const LocationData = [
    {
      value: "0",
      title: "Banner chính",
    },
    {
      value: "1",
      title: "Footer",
    },
    {
      value: "2",
      title: "Thẻ ngân hàng",
    },
    {
      value: "3",
      title: "Trang đăng nhập",
    },
  ];
  const [valueUserLoc, setValueLoc] = useState<string>("");
  const onChangeLoc = (newValue: string) => {
    setValueLoc(newValue);
  };

  // XỬ LÝ FILE IAMGE

  const handleChangeFileBanner = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      setImageBanner(file);
    }
  };

  const handleImageBanner = () => {
    return imageBanner1 ? (
      <Image
        className="my-1"
        style={{ width: "100px", height: "90px" }}
        src={imageBanner1}
        alt="Con Dấu"
      />
    ) : imageBanner ? (
      <Image
        className="my-1"
        style={{ width: "100px", height: "90px" }}
        src={URL.createObjectURL(imageBanner)}
        alt="Con Dấu"
      />
    ) : (
      <p className="border px-[7px] flex rounded-[10px] items-center border-gray-400 w-[100px] h-[90px]">
        Chưa có ảnh
      </p>
    );
  };
  const handelRemoveImageBanner = () => {
    setImageBanner("");
    setImageBanner1("");
  };
  const existingPositions = soViTriDaCoo;
  
  return (
    <Spin spinning={loading} className="border-t pt-[20px]">
      <Form
        form={formEdit}
        name="form_item_path"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          name="tieuDe"
          label=<div className="font-bold mt-[10px]">Tiêu đề</div>
        >
          <Input placeholder="Tiêu đề" />
        </Form.Item>
        <Form.Item
          rules={[
            {
              pattern: /^[0-9]+$/,
              message: "Vui lòng nhập số.",
            },
          ]}
          name="thuTu"
          label=<div className="font-bold mt-[10px]">Thứ tự</div>
        >
          <Input type="text" placeholder="Thứ tự" />
        </Form.Item>
        <Form.Item
          name="moTa"
          label=<div className="font-bold mt-[10px]">Mô tả</div>
        >
          <Input placeholder="Mô tả" />
        </Form.Item>
        <Form.Item
          name="trang"
          label=<div className="font-bold mt-[10px]">Trang</div>
        >
          <TreeSelect
            placeholder="Trang"
            style={{ width: "100%" }}
            value={valuePage}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            treeData={PageData}
            treeDefaultExpandAll
            onChange={onChangePage}
          />
        </Form.Item>
        <Form.Item
          name="viTri"
          label={<div className="font-bold mt-[10px]">Vị trí</div>}
          rules={[
            {
              required: true,
              message: "Vui lòng chọn vị trí.",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                // Check if the selected position is not in the existing positions array
                if (!existingPositions.includes(value)) {
                  return Promise.resolve();
                }

                return Promise.reject("Vị trí này đã có trong bảng.");
              },
            }),
          ]}
        >
          <TreeSelect
            placeholder="Vị trí"
            style={{ width: "100%" }}
            value={valueUserLoc}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            treeData={LocationData}
            treeDefaultExpandAll
            onChange={onChangeLoc}
          />
        </Form.Item>
        <Form.Item label=<div className="font-bold mt-[10px]">Tải file</div>>
          <div className="flex items-center gap-5 ">
            {handleImageBanner()}
            <label
              htmlFor="file-input-condau"
              className="bg-[#DCEDFF] font-bold border p-1 rounded-lg  border-[#1386ED] text-[#1386ED]  cursor-pointer"
            >
              Chọn
            </label>
            <p
              onClick={() => {
                handelRemoveImageBanner();
              }}
              className="bg-[#DCEDFF] font-bold border-[#1386ED] border p-1 rounded-lg text-[#1386ED] cursor-pointer"
            >
              Xóa
            </p>
            <span>Kích thước tối đa 5MB</span>
          </div>
          <input
            type="file"
            id="file-input-condau"
            style={{ display: "none" }}
            onChange={handleChangeFileBanner}
          />
        </Form.Item>
        <div className="flex gap-[15px] justify-end mt-[40px] border-t pt-[10px]">
          <button className="p-[5px]  w-[80px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]">
            Hủy
          </button>
          <button className="p-[5px]  w-[80px] min-w-[40px]  bg-[#0cc77f]  text-[#fff] duration-300 hover:text-yellow-300  hover:bg-[#0a965b]">
            Cộng tiền
          </button>
        </div>
      </Form>
    </Spin>
  );
};

export default Add;

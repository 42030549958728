import React from "react";
import { Form, Input, Button, Switch, TreeSelect } from "antd";
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import { db } from "../../../../config/firebase.config";

const Update = () => {
  const onFinish = (value: any) => {
    if(value.newPassword !== value.newPasswordConfirm) {
      alert("Password mới không khớp!");
      return;
    }

    const { currentPassword, newPassword, confirmPassword } = value;
    const auth = getAuth(db.app);
    const user = auth.currentUser;

    if(!user) return;

    const credential = EmailAuthProvider.credential(
      user?.email ?? "",
      currentPassword
    );

    reauthenticateWithCredential(user!,credential)
      .then(() => {
        // User successfully re-authenticated.
        return updatePassword(user, newPassword);
      })
      .then(() => {
        alert("Cập nhật mật khẩu thành công!");
      })
      .catch((error) => {
        alert("Cập nhật mật khẩu không thành công!");
      });
  };
  return (
    <div>
      <Form name="form_item_path" layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="currentPassword"
          label=<div className="font-bold mt-[10px]">Mật khẩu cũ</div>
        >
          <Input.Password placeholder="input password" />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label=<div className="font-bold mt-[10px]">Mật khẩu mới</div>
        >
          <Input.Password placeholder="input password" />
        </Form.Item>
        <Form.Item name="newPasswordConfirm" label=<div className="font-bold mt-[10px]">Nhập lại mật khẩu</div>>
          <Input.Password placeholder="input password" />
        </Form.Item>

        <button className="p-[5px] mt-[10px] h-[40px] w-full min-w-[40px] font-bold  bg-[#0cc77f] rounded-[10px] text-[#fff]">
          Đổi mật khẩu
        </button>
      </Form>
    </div>
  );
};

export default Update;

import React from 'react'
import { useAppDispatch } from '../../../../../store';
import { ModalProjectAction } from '../../../../../store/Managermodal';
import { collection, getDocs, updateDoc } from 'firebase/firestore';
import toast from 'react-hot-toast';
import { db } from '../../../../../config/firebase.config';
import { Spin } from 'antd';

export default function EditContract({ uid }: { uid: string }) {

    const [isValueCCCD, setIsValueCCCD] = React.useState<string>('');
    const [isValueDate, setIsValueDate] = React.useState<string>('');
    const [isValueMoney, setIsValueMoney] = React.useState<string>('');
    const [isValueRate, setIsValueRate] = React.useState<string>('');

    const dispatch = useAppDispatch();

    const [loading, setLoading] = React.useState<boolean>(false);

    const handleApproveContract = async () => {
        setLoading(true);

        const citiesRef = collection(db, "loan-list");
        const q = await getDocs(citiesRef);
        q.forEach(async (doc) => {
            if (doc.data().uid === uid) {

                await updateDoc(doc.ref, {
                    cmnd: isValueCCCD,
                    BorrowedTime: isValueDate,
                    loan: {
                        loanAmount: isValueMoney,
                        loanInterestRate: isValueRate
                    }
                }).then(() => {
                    setLoading(false);
                    dispatch(ModalProjectAction.CLOSE_MODAL(''));
                }).catch((error) => {
                    setLoading(false);
                    toast.error("Có lỗi xảy ra, vui lòng thử lại sau");
                });
            }
        });

    }

    if (loading) return (
        <div className="py-[60px] flex justify-center items-center">
            <Spin />
        </div>
    )

    return (
        <div>
            <div className=' flex flex-col gap-4'>
                <input type="text" placeholder='Số CMT / CCCD' className='border border-blue-400 rounded-lg py-1 px-3 outline-none'
                    value={isValueCCCD}
                    onChange={(e) => setIsValueCCCD(e.target.value)}
                />
                <input type="date" placeholder='Ngày ký' className='border border-blue-400 rounded-lg py-1 px-3 outline-none'
                    value={isValueDate}
                    onChange={(e) => setIsValueDate(e.target.value)}
                />
                <input type="text" placeholder='Số tiền khoản vay' className='border border-blue-400 rounded-lg py-1 px-3 outline-none'
                    value={isValueMoney}
                    onChange={(e) => setIsValueMoney(e.target.value)}
                />
                <input type="text" placeholder='Lãi suất vay' className='border border-blue-400 rounded-lg py-1 px-3 outline-none'
                    value={isValueRate}
                    onChange={(e) => setIsValueRate(e.target.value)}
                />
            </div>
            <div className='mt-8 w-full flex items-center justify-end gap-4'>
                <button className='py-2 px-4 rounded-lg border border-blue-500 bg-white text-blue-500'
                    onClick={() => dispatch(ModalProjectAction.CLOSE_MODAL(''))}
                >Hủy</button>
                <button className='py-2 px-4 rounded-lg border border-transparent bg-blue-500 text-white'
                    onClick={handleApproveContract}
                >Cập nhật</button>
            </div>
        </div>
    )
}

import { Empty } from 'antd'
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { db } from '../../../../../config/firebase.config';

export default function History({ uid }: { uid: string }) {

    const [isHistory, setIsHistory] = React.useState<any | null>(null);
    const [histories, setHistories] = useState([]);

    const getHistory = async () => {
        const historiesRef = collection(db, "history");
        const q = query(historiesRef, where("uid", "==", uid));
        const snapshots = await getDocs(q);
        const histories = snapshots.docs.map(i => i.data()).reverse();

        setHistories(histories as any);
    }

    useEffect(() => {
        getHistory();

    }, [])

    const formatMoney = (number: number) => {
        number = Math.round(number * 1000) / 1000;

        return number
            .toFixed(0)
            .replace(/\D/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div>
            {
                histories.length > 0 ? (
                    <div>
                        <h1>Lịch sử giao dịch</h1>
                        {
                            histories.map((i: any) => (
                                <p>-{i.note.message}</p>
                            ))
                        }
                    </div>
                ) : (
                    <Empty description={t("NoData")} />
                )
            }
        </div>
    )
}

import { createSlice } from "@reduxjs/toolkit";
import { ReactElement } from "react";

type managerProjectInitialState = {
    setOpenReducer?: boolean,
    ComponentContentModal?: ReactElement,
    width:string
    callBackSubmit?: () => void,
    title?: string
}
const initialState: managerProjectInitialState = {
    setOpenReducer: false,
    width:"0"
}
export const { reducer: ModalProjecttReducer, actions: ModalProjectAction } = createSlice({
    name: "ModalProject",
    initialState,
    reducers: {
        OPEN_EDIT_FORM_MODAL: (state, action) => {
            return { ...state, setOpenReducer: true, ComponentContentModal: action.payload.content, title: action.payload.title,width:action.payload.width }
        },
        CLOSE_MODAL: (state, action) => {
            return { ...state, setOpenReducer: false }
        },
        SET_SUBMIT_EDIT: (state, action) => {
            return { ...state, callBackSubmit: action.payload.submitForm }
        },
        OPEN_CREATE_TASKl: (state, action) => {
            return {...state,setOpenReducer:true,ComponentContentModal:action.payload.content,title:action.payload.title}
        }
    },
    extraReducers: builder => {


    }
})
import { doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../../../../../config/firebase.config";
import { useAppDispatch } from "../../../../../store";
import { ModalProjectAction } from "../../../../../store/Managermodal";
import { Spin } from "antd";
type BlockDrop = {
  id: string;
  status: string;
};
const Block: React.FC<BlockDrop> = ({ id, status }) => {
  const dispatch = useAppDispatch();
  const [isLoad, setIsLoad] = useState(false);

  const updateBlock = async () => {
    setIsLoad(true);
    const userRef = doc(db, "admins", id);

    await updateDoc(userRef, {
      status: status === "active" ? "block" : "active"
    });

    dispatch(ModalProjectAction.CLOSE_MODAL(''));
    window.location.reload();
  }

  if (isLoad) return (
    <div className="py-[60px] flex justify-center items-center">
      <Spin />
    </div>
  )

  return (
    <div>
      <div className="py-[30px] text-center">
        <p className="flex items-center gap-[20px] justify-center">
          <svg
            className="text-yellow-500 text-[26px]"
            viewBox="64 64 896 896"
            focusable="false"
            data-icon="exclamation-circle"
            width="1em"
            height="1em"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
            <path d="M464 688a48 48 0 1096 0 48 48 0 10-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"></path>
          </svg>{" "}
          <span className="font-bold text-[16px]">
            Xác nhận chuyển trạng thái
          </span>
        </p>
        <p>
          Bạn có chắc chắn chuyển trang thái tài khoản thành:{" "}
          <span className="font-bold">{status === "active" ? "Ngưng hoạt động" : "Hoạt động"}</span>?
        </p>
      </div>
      <div className="flex gap-[15px] justify-end mt-[40px]">
        <button className="p-[5px]  w-[80px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]"
          onClick={() => dispatch(ModalProjectAction.CLOSE_MODAL(''))}
        >
          Hủy
        </button>
        <button onClick={updateBlock} className="p-[5px] w-[80px] min-w-[40px]  bg-[#1890ff] border text-[#fff] duration-300 hover:text-[#078ddb] hover:bg-[#fff] hover:border-[#078ddb]">
          Xác nhận
        </button>
      </div>
    </div>
  );
};

export default Block;

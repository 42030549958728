import { Form, Input, Spin } from "antd"
import { t } from "i18next"
import React from "react"
import { useAppDispatch } from "../../../../../store"
import { ModalProjectAction } from "../../../../../store/Managermodal"
import { FieldValue, collection, doc, getDocs, increment, setDoc, updateDoc } from "firebase/firestore"
import { db } from "../../../../../config/firebase.config"
import toast from "react-hot-toast"
import TextArea from "antd/es/input/TextArea"

type ApproveTheContractProps = {
    uid: string;
    edit: boolean;
}

export default function ApproveTheContract({ uid, edit }: ApproveTheContractProps) {

    const dispatch = useAppDispatch()
    const [loading, setLoading] = React.useState<boolean>(false);

    const onFinish = async (value: any) => {
        setLoading(true);

        const citiesRef = collection(db, "loan-list");
        const q = await getDocs(citiesRef);

        if (edit) {
            q.forEach(async (d) => {
                if (d.data().uid === uid) {
                    await setDoc(d.ref, {
                        noiDungDuyet: value.noiDungDuyet || "",
                      
                    }, {
                        merge: true
                    }).then(async () => {
                        window.location.reload()
                    }).catch((error) => {
                        setLoading(false);
                        toast.error("Có lỗi xảy ra, vui lòng thử lại sau");
                    });
                }
            });
        } else {
            q.forEach(async (d) => {
                if (d.data().uid === uid) {
                    await setDoc(d.ref, {
                        status: 'approved',
                        noiDungDuyet: value.noiDungDuyet || "",
                        tieuDeDuyet: value.tieuDeDuyet || "Rút tiền thất bại",
                        
                    }, {
                        merge: true
                    }).then(async () => {
                        const userRef = doc(db, 'users', uid);

                        await updateDoc(userRef, {
                            balance: increment(d.data().loan?.loanAmount ?? 0)
                        });
                        window.location.reload()
                    }).catch((error) => {
                        setLoading(false);
                        toast.error("Có lỗi xảy ra, vui lòng thử lại sau");
                    });
                }
            });
        }

    }

    if (loading) return (
        <div className="py-[60px] flex justify-center items-center">
            <Spin />
        </div>
    )


    return (
        <div>
            <div style={{
                marginTop: 15,
                marginBottom: 15
            }}>
                {
                    !edit &&
                    <p>Bạn có đồng ý <span className="font-bold">duyệt</span> khoản vay này không?</p>
                }
            </div>
            <Form name="form_item_path" layout="vertical" onFinish={onFinish}>
                {
                    !edit &&
                    <Form.Item name="tieuDeDuyet" label=<div className="font-bold mt-[10px]">Tiêu đề</div>>
                        <Input placeholder="Tiêu đề" defaultValue={"Rút tiền thất bại"} />
                    </Form.Item>
                }
                <Form.Item name="noiDungDuyet" label=<div className="font-bold mt-[10px]">Nội dung duyệt</div>>
                    <TextArea placeholder="Nội dung duyệt" />
                </Form.Item>
                <Form.Item name="soTienCanDuyet" label=<div className="font-bold mt-[10px]">Số tiền cần duyệt</div>>
                    <Input placeholder="Số tiền cần duyệt" />
                </Form.Item>

                <div className="flex gap-[15px] justify-end mt-[40px]">
                    <button className="p-[5px]  w-[80px] min-w-[40px]  bg-white border border-[#d9d9d9] text-[#4e4e4e] duration-300 hover:text-[#078ddb]  hover:border-[#078ddb]"
                        onClick={() => dispatch(ModalProjectAction.CLOSE_MODAL(''))}
                    >
                        {t("Cancle")}
                    </button>
                    <button className="p-[5px] w-[80px] min-w-[40px]  bg-[#1890ff] border text-[#fff] duration-300 hover:text-[#078ddb] hover:bg-[#fff] hover:border-[#078ddb]"
                    // onClick={() => handleApproveContract()}
                    >
                        {t("Comfirm")}
                    </button>
                </div>
            </Form>
        </div>
    )
}

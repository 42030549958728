import React, { useState } from "react";
import { Button, Drawer, Modal, Space } from "antd";
import type { DrawerProps } from "antd/es/drawer";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { ModalProjectAction } from "../../store/Managermodal";

const ModalProject: React.FC = (props) => {
  const AppDispatch = useAppDispatch();
  const [size, setSize] = useState<DrawerProps["size"]>();
  const {
    width,
    setOpenReducer,
    ComponentContentModal,
    callBackSubmit,
    title,
  } = useSelector((state: RootState) => state.managerModal);
  const showLargeDrawer = () => {
    setSize("large");
  };

  const onClose = () => {
    AppDispatch(ModalProjectAction.CLOSE_MODAL({}));
  };
  return (
    <>
      <Modal
        width={width}
        onCancel={onClose}
        title={title}
        open={setOpenReducer}
      >
        {ComponentContentModal}
      </Modal>
    </>
  );
};

export default ModalProject;
